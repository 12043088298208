import React, { useMemo } from 'react';
import { SeriesFundamentalService } from 'application/services';
import { formatDateTime, getTimeFormatted } from './utils';
import { Tooltip } from 'presentation/components';
import { FundamentalStatus, FundamentalType } from 'domain/models';
import { generatePath } from 'react-router';
import { routes } from 'main/router';
import { Link, useSearchParams } from 'react-router-dom';

type Props = {
  fundamental?: SeriesFundamentalService.Response['modelLivePreviewSeries']['series'][number]['fundamentals'][number];
};

export const Fundamental = ({ fundamental }: Props) => {
  const [params] = useSearchParams();

  const displayTime = params.get('d') === 'time';

  const fundamentalIsPublished = fundamental?.status === FundamentalStatus.PUBLISHED;
  const fundamentalIsPending = fundamental?.status === FundamentalStatus.PENDING;

  const value = useMemo(() => {
    if (!fundamental) return '-';

    if (fundamentalIsPending) return 'PENDING';

    const { value, normalizedValue } = fundamental;

    let valueToDisplay = normalizedValue ?? value;

    if (valueToDisplay) {
      const parsedValue = parseFloat(valueToDisplay);
      valueToDisplay =
        parsedValue < 0 ? `(${Math.abs(parsedValue).toFixed(2)})` : parsedValue.toFixed(2);
    } else {
      valueToDisplay = '-';
    }

    return valueToDisplay;
  }, [fundamental, fundamentalIsPending]);

  const publishedIn = useMemo(() => {
    if (!fundamental?.publishedIn || value === '-' || value === 'PENDING') return '-';

    const formattedPublishedIn = getTimeFormatted(fundamental.publishedIn);

    return `${formattedPublishedIn}`;
  }, [fundamental, value]);

  const fundamentalURL = useMemo(() => {
    if (!fundamental?.id || value === '-' || value === 'PENDING') return '';

    return generatePath(routes.SOURCE, { id: fundamental?.id });
  }, [fundamental?.id, value]);

  const className = useMemo(() => {
    if (fundamentalIsPending) return 'pending';

    return '';
  }, [fundamentalIsPending]);

  const tooltipContent = useMemo(() => {
    let content = '';

    if (!fundamental || value === '-' || fundamentalIsPending) return content;

    const publishTime = formatDateTime(fundamental?.publishedAt ?? '');

    content = 'This data is updated via ';

    if (fundamentalIsPublished) {
      content += 'Incremental Updates';
    } else {
      content += 'regular model upload';
    }

    if (fundamental.document?.filing.acceptedDate) {
      const formatedDate = formatDateTime(fundamental.document?.filing.acceptedDate);
      content += `\nFiling dropped at ${formatedDate}`;
    }

    if (
      fundamental.type === FundamentalType.FILLER_VALUE ||
      fundamental.type === FundamentalType.COMPOSITE
    ) {
      const publishedAt = formatDateTime(fundamental.publishedAt);
      content += `\nIngested at ${publishedAt}`;
    } else {
      const createdAt = formatDateTime(fundamental.document?.createdAt);
      content += `\nIngested at ${createdAt}`;
    }

    if (fundamentalIsPublished) {
      content += `\nData updated at ${publishTime}`;
    } else {
      content += `\nModel updated at ${publishTime}`;
    }

    return content;
  }, [fundamental, fundamentalIsPending, fundamentalIsPublished, value]);

  if (!fundamental) return <td>-</td>;

  return (
    <Tooltip title={tooltipContent} placement="top" arrow>
      <td className={className}>
        {!!fundamentalURL && (
          <>
            {displayTime && publishedIn}
            {!displayTime && <Link to={fundamentalURL}>{value}</Link>}
          </>
        )}
        {!fundamentalURL && value}
      </td>
    </Tooltip>
  );
};
