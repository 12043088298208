import React, { useState } from 'react';
import { FundamentalInfoService } from 'application/services';
import { styled } from '@mui/material';
import { generatePath } from 'react-router';
import { routes } from 'main/router';
import { Mixpanel } from 'infra/mixpanel';
import { Link } from 'react-router-dom';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minHeight: '25px',
  maxHeight: '100%',
  transition: 'flex 0.3s ease-in-out, min-height 0.3s ease-in-out',

  '&[data-open="true"]': {
    minHeight: '45px',
    flex: '1 5 auto'
  }
});

const Header = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  paddingBottom: '0.5rem',
  fontSize: '0.75rem',
  color: '#000000',
  fontWeight: 700,
  cursor: 'pointer',

  '& > svg': {
    transition: 'transform 0.3s ease-in-out',

    '&[data-open="false"]': {
      transform: 'rotate(180deg)'
    }
  }
});

const TableWrapper = styled('div')({
  width: '100%',
  transition: 'max-height 0.3s ease-in-out',
  maxHeight: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',

  '&[data-open="false"]': {
    maxHeight: 0
  },

  '& > table': {
    background: '#FFFFFF',
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    fontSize: '0.75rem',

    '& thead': {
      '& tr th': {
        fontWeight: 400
      }
    },

    '& tr': {
      border: '1px solid #0000001F',
      borderLeftColor: 'transparent',
      borderRight: '2px solid transparent',

      '& th, & td': {
        textAlign: 'right',
        padding: '0.5rem 1rem',
        color: '#000000DE',

        '&:first-of-type': {
          textAlign: 'left',
          wordBreak: 'break-word',
          background: '#F5F5F5'
        },
        '&:last-of-type': {
          paddingLeft: 0
        }
      },

      '& td': {
        '&.value': {
          minWidth: 'fit-content',
          whiteSpace: 'nowrap'
        },

        '& a': {
          color: '#000000DE'
        }
      }
    }
  }
});

const TableRow = styled('tr')<{ selected?: boolean }>(({ selected }) => ({
  background: selected ? '#2196F314 !important' : '#FFFFFF',
  outline: selected ? '1px solid #2196F380' : 'none',

  '& td, & a': {
    fontWeight: selected ? 700 : 400,
    background: selected ? 'transparent !important' : 'inherit'
  }
}));

const CaretDown = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      width="16"
      height="16"
      transform="matrix(-1 0 0 1 16 0)"
      fill="white"
      fillOpacity="0.01"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.5391 6.59371C11.3516 6.40626 11.0477 6.40626 10.8603 6.59371L7.99968 9.45431L5.13908 6.59371C4.95167 6.40626 4.64778 6.40626 4.46026 6.59371C4.27284 6.78117 4.27284 7.08508 4.46026 7.27254L7.66027 10.4725C7.7503 10.5626 7.87238 10.6131 7.99968 10.6131C8.12699 10.6131 8.24908 10.5626 8.3391 10.4725L11.5391 7.27254C11.7266 7.08508 11.7266 6.78117 11.5391 6.59371Z"
      fill="#1C2024"
    />
  </svg>
);

type Props = {
  fundamentalId: string;
  history?: FundamentalInfoService.Fundamental[];
};

export const SeriesHistoryTable = ({ fundamentalId, history = [] }: Props) => {
  const [expanded, setExpanded] = useState(true);

  const handleFundamentalClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
    Mixpanel.track('marketplace:src:restatement:click', { fundamentalId: event.currentTarget.id });
  };

  const toggleExpanded = () => {
    setExpanded((prev) => !prev);
  };

  return (
    <Container data-open={expanded.toString()}>
      <Header onClick={toggleExpanded}>
        Series History
        <CaretDown data-open={expanded.toString()} />
      </Header>
      <TableWrapper data-open={expanded.toString()}>
        <table>
          <thead>
            <tr>
              <th>Period</th>
              <th>Value</th>
            </tr>
          </thead>
          <tbody>
            {history?.map(({ id, period, value }) => (
              <TableRow key={id} id={id} selected={id === fundamentalId}>
                <td>{period}</td>
                <td className="value">
                  <Link
                    id={id}
                    to={generatePath(routes.SOURCE, { id })}
                    onClick={handleFundamentalClick}
                  >
                    {value}
                  </Link>
                </td>
              </TableRow>
            ))}
          </tbody>
        </table>
      </TableWrapper>
    </Container>
  );
};
