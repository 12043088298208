import { styled } from '@mui/material';

export const Container = styled('div')(() => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  padding: '24px 0 48px'
}));

export const Table = styled('table')(() => ({
  position: 'relative',
  display: 'grid',
  borderCollapse: 'collapse',
  marginTop: '0.75rem',
  gridTemplateColumns: '4fr 1fr repeat(4, 1.5fr)',

  '& > thead': {
    display: 'contents',

    '& > tr': {
      position: 'sticky',
      display: 'contents',
      top: 0,
      zIndex: 1,

      '& > th': {
        padding: '0.53rem 1rem 0.525rem',
        background: '#FAFAFA',
        borderTop: '1px',
        borderBottom: '1px',
        borderLeft: 0,
        borderRight: 0,
        borderStyle: 'solid',
        borderColor: '#E8E8E8',
        color: '#00000099',
        fontSize: '0.75rem',
        fontWeight: 500,
        textAlign: 'left'
      }
    }
  },

  '& > tbody': {
    display: 'contents',

    '& > tr': {
      display: 'contents'
    }
  },

  '& td.full-width': {
    gridColumn: '1 / -1',
    display: 'flex',
    justifyContent: 'center',
    paddingBottom: '1rem'
  }
}));
