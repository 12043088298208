import { QueryHookOptions, useQuery } from '@apollo/client';
import {
  COMPANY_DETAILS_SERVICE,
  COMPANY_MODEL_PREVIEW_SERVICE,
  SERIES_FUNDAMENTAL_SERVICE,
  CompanyDetailsService,
  CompanyModelPreviewService,
  SeriesFundamentalService
} from 'application/services';
import { Mixpanel } from 'infra/mixpanel';
import { useToastContext } from 'presentation/contexts';

export const useCompanyServices = () => {
  const toast = useToastContext();

  const useCompanyDetails = (
    options?: QueryHookOptions<CompanyDetailsService.Response, CompanyDetailsService.Params>
  ) =>
    useQuery<CompanyDetailsService.Response, CompanyDetailsService.Params>(
      COMPANY_DETAILS_SERVICE,
      {
        fetchPolicy: 'no-cache',
        onCompleted: ({ company }) => {
          if (!company.id) {
            Mixpanel.track('marketplace:company:fetch_failed', {
              variables: options?.variables
            });
          }
        },
        onError: () => {
          Mixpanel.track('marketplace:company:fetch_failed', {
            variables: options?.variables
          });
        },
        ...options
      }
    );

  const useCompanyModelPreview = (
    options?: QueryHookOptions<
      CompanyModelPreviewService.Response,
      CompanyModelPreviewService.Params
    >
  ) =>
    useQuery<CompanyModelPreviewService.Response, CompanyModelPreviewService.Params>(
      COMPANY_MODEL_PREVIEW_SERVICE,
      {
        fetchPolicy: 'no-cache',
        onCompleted: ({ modelLivePreviewSections }) => {
          if (!modelLivePreviewSections.sections) {
            Mixpanel.track('marketplace:company_sections:fetch_failed', {
              variables: options?.variables
            });
            toast.error('Failed to fetch company model sections');
          }
        },
        onError: () => {
          Mixpanel.track('marketplace:company_sections:fetch_failed', {
            variables: options?.variables
          });
          toast.error('Failed to fetch company model sections');
        },
        ...options
      }
    );

  const useSeriesFundamental = (
    options?: QueryHookOptions<SeriesFundamentalService.Response, SeriesFundamentalService.Params>
  ) =>
    useQuery<SeriesFundamentalService.Response, SeriesFundamentalService.Params>(
      SERIES_FUNDAMENTAL_SERVICE,
      {
        fetchPolicy: 'no-cache',
        onCompleted: ({ modelLivePreviewSeries }) => {
          if (!modelLivePreviewSeries.periods) {
            Mixpanel.track('marketplace:company_series:fetch_failed', {
              variables: options?.variables
            });
            toast.error('Failed to fetch company model series');
          }
        },
        onError: () => {
          Mixpanel.track('marketplace:company_series:fetch_failed', {
            variables: options?.variables
          });
          toast.error('Failed to fetch company model series');
        },
        ...options
      }
    );

  return {
    companyDetails: useCompanyDetails,
    companyModelPreview: useCompanyModelPreview,
    seriesFundamental: useSeriesFundamental
  };
};
