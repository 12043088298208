import { styled } from '@mui/material';

export const Section = styled('tr')<{ open: boolean }>(({ open }) => ({
  cursor: 'pointer',

  '& > td': {
    display: 'flex',
    gap: '0.5rem',
    alignItems: 'center',
    height: '35px',
    padding: '0.55rem 1rem 0.5rem',
    background: '#F5F5F5',
    borderTop: '0',
    borderBottom: '1px',
    borderLeft: 0,
    borderRight: 0,
    borderStyle: 'solid',
    borderColor: '#E8E8E8',
    color: '#00000099',
    fontSize: '0.75rem',
    fontWeight: 500,
    textAlign: 'left',
    gridColumn: '1 / -1',

    '& > svg': {
      transform: open ? 'rotate(90deg)' : 'rotate(0deg)',
      transition: 'transform 0.15s ease-in-out'
    }
  }
}));

export const DataRow = styled('tr')(() => ({
  '&:last-of-type > td': {
    borderBottom: 0
  },

  '& > td': {
    height: '35px',
    transition: 'height 0.3s ease-in-out, padding 0.3s ease-in-out',
    overflow: 'hidden',

    padding: '0.53rem 1rem 0.525rem',
    paddingLeft: '1rem',
    paddingRight: '1rem',
    background: '#FFFFFF',
    borderTop: 0,
    borderBottom: '1px',
    borderLeft: 0,
    borderRight: 0,
    borderStyle: 'solid',
    borderColor: '#E8E8E8',
    color: '#000000DE',
    fontSize: '0.875rem',
    textAlign: 'left',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&:first-of-type': {
      paddingLeft: '3rem'
    },

    '& > a': {
      color: '#000000DE'
    },

    '&.pending': {
      color: '#E2A336'
    }
  }
}));
