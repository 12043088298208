import React, { useEffect } from 'react';
import { useCompanyServices, usePageTitle, useParams } from 'presentation/hooks';
import { LiveUpdatesPageHeader } from './components/LiveUpdatesPageHeader/LiveUpdatesPageHeader';
import { Container } from './LiveUpdatesPage.styles';
import { CircularProgress } from '@mui/material';
import { SubMenu } from './components/SubMenu/SubMenu';
import { UpdatesTable } from './components/UpdatesTable/UpdatesTable';
import { Mixpanel } from 'infra/mixpanel';
import { UpdatesPageError } from './components/UpdatesPageError/UpdatesPageError';

export const LiveUpdatesPage = () => {
  const { companyId } = useParams<'LIVE_UPDATES'>();

  const { data, loading, error } = useCompanyServices().companyDetails({
    variables: { companyId }
  });

  usePageTitle(data?.company?.ticker);

  useEffect(() => {
    if (data?.company) {
      Mixpanel.track('marketplace:updates_page:open', { ...data?.company });
    }
  }, [data?.company]);

  if (loading || !data) return <CircularProgress size={16} style={{ margin: '0 auto' }} />;

  if (error) {
    return <UpdatesPageError />;
  }

  return (
    <Container>
      <LiveUpdatesPageHeader {...data?.company} />
      <SubMenu />
      <UpdatesTable />
    </Container>
  );
};
