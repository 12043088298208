import { gql } from '@apollo/client';
import { FundamentalType } from 'domain/models';

export const FUNDAMENTAL_INFO_SERVICE = gql`
  query getFundamentalInfo($fundamentalId: Int!) {
    fundamentalInfo(fundamentalId: $fundamentalId) {
      id
      type
      period
      value
      contextSummary
      fullSeriesName
      document {
        reportedDate
        filingType
        documentType
        url
        __typename
      }
      company {
        ticker
        name
        __typename
      }
      referenceFundamental {
        id
        value
        period
        __typename
      }
      restatedFundamentals {
        id
        value
        period
      }
      seriesHistory {
        id
        period
        value
      }
      __typename
    }
  }
`;

export namespace FundamentalInfoService {
  export type Params = {
    fundamentalId: string;
  };

  export type Response = {
    fundamentalInfo: FundamentalInfo;
  };

  export type Fundamental = {
    id: string;
    period: string;
    value: string;
  };

  type FundamentalInfo = {
    id: string;
    type: FundamentalType;
    period: string;
    value: string;
    contextSummary: string;
    fullSeriesName: string;
    document: {
      reportedDate: string;
      filingType: string;
      documentType: string;
      url: string;
    };
    company: {
      ticker: string;
      name: string;
    };
    referenceFundamental: Fundamental;
    restatedFundamentals: Fundamental[];
    seriesHistory: Fundamental[];
  };
}
