import React, { forwardRef } from 'react';

export const RightChevronFilledIcon = forwardRef<SVGSVGElement, React.SVGProps<SVGSVGElement>>(
  (props, ref) => (
    <svg
      width="1em"
      height="1em"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={ref}
    >
      <path d="M5 3L11 8L5 13V3Z" fill="black" fillOpacity="0.56" />
    </svg>
  )
);
