import React, { useEffect } from 'react';
import { ModelsTable } from 'presentation/components';
import { usePageTitle, useSelectors } from 'presentation/hooks';
import { HomePagePlaceholder, UserBanner } from './components';
import { AuthorizedContainer, Container, SplashText } from './HomePage.styles';
import { FreeTierBanner } from './components/FreeTierBanner/FreeTierBanner';
import { Mixpanel } from 'infra/mixpanel';

export const HomePage = () => {
  const { isAuthenticated } = useSelectors.Auth();
  const { isFreeTier } = useSelectors.User();

  usePageTitle();

  useEffect(() => {
    Mixpanel.track('marketplace:home_page:open');
  }, []);

  if (!isAuthenticated) {
    return (
      <Container>
        <HomePagePlaceholder />
        <SplashText>
          Once you login, you&apos;ll be able to view all your followed Data Sheets here and see
          their earnings update status. We&apos;ll notify you when an earnings update starts and
          completes.
          <br />
          Discover and follow Data Sheets by searching!
        </SplashText>
      </Container>
    );
  }
  return (
    <AuthorizedContainer>
      <UserBanner />
      {isFreeTier && <FreeTierBanner />}
      <ModelsTable subscribedOnly />
    </AuthorizedContainer>
  );
};

export default HomePage;
