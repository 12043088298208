import { useMutation } from '@apollo/client';
import {
  MODEL_DOWNLOAD_SERVICE,
  ModelDownloadService,
  USER_DETAILS_SERVICE
} from 'application/services';
import { Mixpanel } from 'infra/mixpanel';

export const useGeneralServices = () => {
  const useModelDownload = () =>
    useMutation<ModelDownloadService.Response, ModelDownloadService.Params>(
      MODEL_DOWNLOAD_SERVICE,
      {
        fetchPolicy: 'no-cache',
        refetchQueries: [USER_DETAILS_SERVICE],
        onCompleted: ({ modelDownload }, opt) => {
          const requestFailed =
            !modelDownload?.url || !modelDownload.success || !!modelDownload?.errors?.length;

          if (requestFailed) {
            Mixpanel.track('marketplace:models:download:fail', {
              variables: opt?.variables,
              error: modelDownload?.errors?.[0]
            });
          }
        },
        onError: (e, opt) => {
          Mixpanel.track('marketplace:models:download:fail', {
            variables: opt?.variables,
            error: e?.message
          });
        }
      }
    );

  return {
    modelDownload: useModelDownload
  };
};
