export enum MODEL_STATUS {
  IN_PROGRESS = 'In progress',
  NO_PENDING_UPDATES = 'No pending updates',
  READY_FOR_UPDATE = 'Ready for update'
}

export enum MODEL_SUBSCRIPTION_ERRORS {
  TRIAL_TIER_NOT_ALLOWED = 'This feature isn’t available in your current plan.\n Upgrade to unlock more!',
  FREE_TIER_NOT_ALLOWED = 'This feature isn’t available in your current plan.\n Upgrade to unlock more!',
  STUDENT_TIER_NOT_ALLOWED = 'This feature isn’t available in your current plan.\n Upgrade to unlock more!',
  MAX_SUBSCRIPTIONS_EXCEEDED = 'You’ve reached your subscription limit. Upgrade to unlock more!',
  ACCESS_DENIED = 'This company is not available on the current plan.\n Upgrade to unlock more!'
}

export type IndustryModel = {
  description: string;
  id: string;
  industry: string;
  latestQuarter: string;
};

export type ModelType =
  | 'clientview'
  | 'clientview_with_source_unit_columns'
  | 'clientview_snapshot'
  | 'clientview_snapshot_with_source_unit_columns'
  | 'industry_model';

export enum FundamentalStatus {
  NOT_RELEASED = 'NOT_RELEASED',
  PENDING = 'PENDING',
  PUBLISHED = 'PUBLISHED',
  CLIENTVIEW = 'CLIENTVIEW'
}

export enum FundamentalType {
  COMPOSITE = 'COMPOSITE',
  FILLER_VALUE = 'FILLER_VALUE',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT'
}

export const FILING_TYPES_OPTIONS = {
  '10-K/Q': ['10-K', '10-Q', '10-QT', '10-K/A', '10-Q/A', '10-KT', '10-KT/A'],
  '8-K': ['8-K', '8-K/A'],
  '6-K': ['6-K', '6-K/A'],
  '20-F': ['20-F', '20-F/A'],
  '40-F': ['40-F', '40-F/A'],
  'S-1': ['S-1', 'S-1/A', 'S-11'],
  'S-3': ['S-3', 'S-3/A'],
  'S-4': ['S-4', 'S-4/A'],
  'F-1': ['F-1', 'F-1/A'],
  '20FR12B': ['20FR12B', '20FR12B/A'],
  '424B3': ['424B3', '424B4', '424B1', '424B2', '424B5'],
  DRS: ['DRS', 'DRS/A'],
  'F-4': ['F-4', 'F-4/A'],
  '10-12B': ['10-12B', '10-12B/A'],
  Others: ['Others'],
  Transcript: ['Transcript']
};

export type Fundamental = {
  id: string;
  value: string;
  normalizedValue: string;
  period: string;
  context: string;
  status: FundamentalStatus;
  publishedAt: string;
  publishedIn: number;
  createdAt: string;
  document: {
    filing: {
      acceptedDate: string;
    };
    filingType: string;
    url: string;
    createdAt: string;
  };
  type: FundamentalType;
};

export type Series = {
  id: string;
  name: string;
  rowNumber: number;
  document: {
    createdAt: string;
    filingType: string;
    id: string;
    url: string;
  };
  fundamentals: Fundamental[];
};
