import React, { useState } from 'react';
import { ActionButton, Button, CloseIcon, TextInput } from 'presentation/components';
import { styled } from '@mui/material';
import { Mixpanel } from 'infra/mixpanel';
import { useSourceServices } from 'presentation/hooks';
import { useSearchParams } from 'react-router-dom';

const ReportPopUp = styled('div')<{ isAddinView: boolean }>(({ isAddinView }) => ({
  position: 'absolute',
  bottom: isAddinView ? 'unset' : 20,
  top: isAddinView ? 54 : 'unset',
  left: isAddinView ? 20 : 'calc(100% + 20px)',
  display: 'flex',
  flexDirection: 'column',
  gap: '1rem',
  width: '352px',
  height: '423px',
  background: 'white',
  border: '1px solid #CFD1D1',
  borderRadius: '10px',
  padding: '1rem',
  zIndex: 1000,

  '& > div': {
    '&:first-of-type': {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      paddingBottom: '1rem',
      fontSize: '0.875rem',
      fontWeight: 500,
      borderBottom: '1px solid #E0E0E0',
      color: '#000000DE',

      '& svg': {
        fontSize: '1.5rem',
        cursor: 'pointer',
        color: '#0000008F'
      }
    }
  },

  '& .textarea': {
    height: '100%'
  }
}));

type Params = {
  id: string;
};

export const DocumentReportAnIssue = ({ id }: Params) => {
  const [search] = useSearchParams();

  const isAddinView = search.get('excel_plugin') === 'true';

  const [open, setOpen] = useState(false);
  const [description, setDescription] = useState('');

  const [reportError, { loading }] = useSourceServices().errorReport();

  const eventPrefix = 'marketplace:src:error_report:'.concat(isAddinView ? 'addin:' : '');

  const openPopUp = () => {
    setOpen(true);
    Mixpanel.track(`${eventPrefix}open`);
  };
  const closePopUp = () => {
    Mixpanel.track(`${eventPrefix}close`);
    setOpen(false);
  };

  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const handleSubmit = () => {
    if (description) {
      const variables = {
        fundamentalId: Number(id),
        description,
        errorType: 'wrong data tagged'
      };
      reportError({
        variables
      }).then((res) => {
        if (!res?.errors && !res?.errors?.length) {
          setOpen(false);
        }
      });
      Mixpanel.track(`${eventPrefix}submit`, variables);
    }
  };

  return (
    <>
      <ActionButton variant="text" error onClick={openPopUp}>
        Report an issue
      </ActionButton>
      {open && (
        <ReportPopUp isAddinView={isAddinView}>
          <div>
            Report <CloseIcon onClick={closePopUp} />
          </div>
          <TextInput
            type="textarea"
            className="textarea"
            label="Description"
            onChange={handleChange}
          />
          <Button
            type="submit"
            variant="contained"
            size="small"
            disabled={loading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </ReportPopUp>
      )}
    </>
  );
};
