import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { Iframe } from './DocumentViewer.styles';
import { useRemoteHTMLDocument } from 'presentation/hooks';
import { CircularProgress } from '@mui/material';
import { generatePath, useNavigate } from 'react-router';
import { routes } from 'main/router';
import { Mixpanel } from 'infra/mixpanel';

type Props = {
  className?: string;
  url: string;
  src?: string;
  title: string;
  dataLoading?: boolean;
};

export const DocumentViewer = forwardRef<HTMLIFrameElement, Props>(
  ({ url, dataLoading, ...rest }, ref) => {
    const navigate = useNavigate();

    const [iframeRef, setIframRef] = useState<HTMLIFrameElement | null>(null);
    const [iframeLoading, setIframLoading] = useState(true);

    const { content, loading } = useRemoteHTMLDocument(url, dataLoading);

    useImperativeHandle(ref, () => iframeRef as HTMLIFrameElement);

    const handleLoad = () => {
      // load prime check from doppler
      if (!iframeRef) {
        return;
      }

      if (iframeRef?.contentWindow) {
        const iframeWindow = iframeRef.contentWindow as Window & { BACKEND_URL: string };

        iframeWindow.BACKEND_URL = `${process.env.REACT_APP_BACKEND_URL}${url}`;

        const iframeDocument = iframeRef.contentDocument || iframeRef.contentWindow?.document;
        if (iframeDocument) {
          const style = iframeDocument.createElement('style');
          style.innerHTML = `
            ::-webkit-scrollbar {
              width: 6px;
              height: 6px;
            }
            ::-webkit-scrollbar-thumb {
              background-color: #bdbdbd;
              border-radius: 8px;
              background-clip: padding-box;
            }
            ::-webkit-scrollbar-track {
              background-color: #f1f1f1;
              border-radius: 8px;
            }
            ::-webkit-scrollbar-thumb:hover {
              background-color: #a0a0a0;
            }

            body {
            padding: 0 16px;
            }
          `;
          iframeDocument.head.appendChild(style);

          // Attach a click listener for anchor tags
          iframeDocument.body.addEventListener('click', (e: MouseEvent) => {
            // Only handle left clicks
            if (e.button !== 0) return;

            const target = e.target as HTMLElement;
            const anchor = target.closest('a');
            if (anchor) {
              const href = anchor.getAttribute('href') || '';
              // If the href is a full URL, prevent navigation
              if (/^https?:\/\//.test(href)) {
                e.preventDefault();
              }
            }
          });

          setTimeout(() => {
            setIframLoading(false);
          }, 2000);
        }

        window.onmessage = function (e: MessageEvent) {
          if (e.data.type === 'linkClicked') {
            navigate(generatePath(routes.SOURCE, { id: e.data.id }));
            Mixpanel.track('marketplace:document:link:clicked', { id: e.data.id });
          }
        };
      }
    };

    return (
      <>
        {(loading || dataLoading || iframeLoading) && (
          <CircularProgress size={16} style={{ margin: ' 32px auto' }} />
        )}
        <Iframe
          src="about:blank"
          {...rest}
          ref={setIframRef}
          className={iframeLoading || dataLoading ? 'loading' : rest.className}
          srcDoc={content}
          onLoad={handleLoad}
        />
      </>
    );
  }
);
