import React, { useRef } from 'react';
import { Container, Header, Wrapper } from './DocumentViewPage.styles';
import { usePageTitle, useParams, useSourceServices } from 'presentation/hooks';
import { DocumentViewer, SideBar } from 'presentation/components';
import { CircularProgress } from '@mui/material';

export const DocumentViewPage = () => {
  const { id } = useParams<'DOCUMENT'>();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const url = `embed/document/${id}`;

  const { data, loading } = useSourceServices().documentInfo({
    variables: {
      documentId: id
    }
  });

  usePageTitle(`${data?.documentInfo?.company?.ticker} - ${data?.documentInfo?.filingType}`);

  if (loading || !data?.documentInfo) {
    return <CircularProgress size={16} style={{ margin: ' 32px auto' }} />;
  }

  const { company, ...document } = data?.documentInfo;

  return (
    <Container>
      <SideBar
        id={id}
        company={company}
        document={document}
        type="document"
        iframeRef={iframeRef}
      />
      <Wrapper>
        <Header>
          {company.ticker} - {company.name}
        </Header>
        <DocumentViewer ref={iframeRef} url={url} title="document-viewer" />
      </Wrapper>
    </Container>
  );
};
