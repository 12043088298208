import React, { useState } from 'react';
import { Container, Label, StyledInput, StyledTextarea } from './TextInput.styles';
import { IconButton, TextareaAutosizeProps, TextFieldProps } from '@mui/material';
import { EyeClosedIcon, EyeOpenIcon } from '../Icons';

type TextInputProps = {
  label?: string;
  error?: boolean;
  success?: boolean;
  info?: boolean;
  warning?: boolean;
};

type TextFieldInputProps = TextInputProps &
  TextFieldProps & { type?: 'text' | 'password' | 'email' | 'number' };
type TextareaInputProps = TextInputProps & TextareaAutosizeProps & { type: 'textarea' };

type Props = TextFieldInputProps | TextareaInputProps;

const isTextareaProps = (props: Omit<Props, 'variant'>): props is TextareaInputProps => {
  return props.type === 'textarea';
};
const isTextFieldProps = (props: Omit<Props, 'variant'>): props is TextFieldInputProps => {
  return props.type !== 'textarea';
};

export const TextInput = ({ className, id, label, name, value, error, ...rest }: Props) => {
  const [inputType, setInputType] = useState(rest.type);

  const togglePasswordVisibility = () => {
    if (rest.type === 'password' && !!value) {
      setInputType((prev) => (prev === 'password' ? 'text' : 'password'));
    }
  };

  const eyeIcon = inputType === 'password' ? <EyeClosedIcon /> : <EyeOpenIcon />;
  const EndAddornment =
    rest.type === 'password' ? (
      <IconButton onClick={togglePasswordVisibility}>{eyeIcon}</IconButton>
    ) : (
      (rest as TextFieldProps)?.InputProps?.endAdornment
    );

  return (
    <Container className={className} error={error}>
      {label && <Label htmlFor={id ?? name}>{label}</Label>}
      {isTextareaProps(rest) && <StyledTextarea minRows={3} {...rest} />}
      {isTextFieldProps(rest) && (
        <StyledInput
          {...rest}
          id={id ?? name}
          type={inputType}
          name={name}
          value={value}
          error={error}
          InputProps={{
            ...rest.InputProps,
            endAdornment: EndAddornment
          }}
        />
      )}
    </Container>
  );
};
