import { styled } from '@mui/material';

export const Container = styled('div')({
  position: 'relative',
  display: 'flex',
  minWidth: 'fit-content',
  height: '100vh',
  borderRight: '1px solid #E0E0E0',
  background: '#FAFAFA'
});

export const Content = styled('div')<{ collapsed: boolean }>(({ collapsed }) => ({
  display: 'flex',
  width: collapsed ? 0 : '307px',
  overflow: 'hidden',
  transition: 'width 0.3s ease-in-out, min-width 0.3s ease-in-out',

  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem',
    minWidth: '307px',

    '& > button, & > a > button': {
      width: '100%'
    },
    '& > button:not(:first-of-type), & > a': {
      marginTop: '0.5rem'
    }
  },

  '& .content-wrapper': {
    display: 'flex',
    flexDirection: 'column',
    gap: '1.5rem',
    margin: '1rem 0',
    flex: 1,
    minHeight: 0
  }
}));

export const ToggleButton = styled('div')<{ collapsed: boolean }>(({ collapsed }) => ({
  position: 'absolute',
  right: -29,
  top: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '30px',
  border: '1px solid #E0E0E0',
  borderLeftColor: 'transparent',
  background: '#FAFAFA',
  cursor: 'pointer',
  zIndex: 1,

  '& > svg': {
    fontSize: '1.125rem',

    transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.3s ease-in-out'
  }
}));

export const PageInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minWidth: 'fit-content',
  paddingBottom: '1.25rem',
  borderBottom: '1px solid #00002F26',
  fontSize: '0.75rem',
  fontWeight: 700,

  '& > span': {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    fontSize: '1.25rem',
    fontWeight: 500
  }
});

export const Details = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  marginTop: '1.5rem',

  '& > div': {
    display: 'flex',
    fontSize: '0.875rem',
    color: '#00000099',

    '& > span': {
      width: '100px',
      minWidth: '100px',
      fontWeight: 500,
      color: '#000000'
    }
  }
});
