import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  CompanyName,
  Container,
  Header,
  Notification,
  NotificationBadge,
  Sections,
  Timestamp,
  Wrapper
} from './UserNotifications.styles';
import { BellIcon, CloseIcon } from '../Icons';
import { useStatsigClient } from '@statsig/react-bindings';
import { useSelectors, useUserServices } from 'presentation/hooks';
import { NOTIFICATION_POLLING_DELAY } from 'main/constants';
import { Portal } from '../Portal/Portal';
import { ClickAwayListener } from '../ClickAwayListener/ClickAwayListener';
import { routes } from 'main/router';
import { generatePath } from 'react-router';
import { Button } from '@mui/material';
import { Tooltip } from '../Tooltip/Tooltip';
import { Mixpanel } from 'infra/mixpanel';

type Props = {
  expanded?: boolean;
};

export const UserNotifications = ({ expanded }: Props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const statsig = useStatsigClient().client;

  const [open, setOpen] = useState(false);

  const { menuExpanded } = useSelectors.General();
  const { isAnalyst } = useSelectors.User();

  const notificationsEnabled = useMemo(
    () => !isAnalyst && statsig.checkGate('earnings_notification_enabled'),
    [isAnalyst, statsig]
  );

  const { data, startPolling, stopPolling } = useUserServices().getNotifications({
    skip: !notificationsEnabled,
    onError(error) {
      console.error('[Marketplace] User Notifications - Failed to fetch notifications', error);
    }
  });
  const [markSeenNotifications] = useUserServices().markSeenNotifications();

  const notificationsCount = useMemo(() => {
    const count = data?.earningUpdateNotifications?.filter(({ seen }) => !seen).length ?? 0;

    if (!count) return;

    return count > 999 ? '999+' : count;
  }, [data?.earningUpdateNotifications]);

  const handleOpen = () => {
    setOpen(true);
    Mixpanel.track('marketplace:menu:notification_bell_icon:click');
  };

  const handleClose = () => {
    setOpen(false);
    Mixpanel.track('marketplace:menu:notifications:close');
  };

  const formatDate = useCallback((dateString: string) => {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      hour12: true
    };
    return date.toLocaleString('en-US', options);
  }, []);

  const handleUpdateClick = useCallback(
    (company: { id: number; name: string; ticker: string }) => () => {
      Mixpanel.track('marketplace:notifications:item:click', {
        item: company
      });
    },
    []
  );

  useEffect(() => {
    if (!notificationsEnabled) {
      stopPolling();
    } else {
      startPolling(NOTIFICATION_POLLING_DELAY);
    }

    return () => {
      stopPolling();
    };
  }, [notificationsEnabled, stopPolling, startPolling]);

  useEffect(() => {
    if (open) {
      void markSeenNotifications({ variables: { companyIds: [''] } });
      Mixpanel.track('marketplace:notifications:mark_all_as_read');
    }
  }, [markSeenNotifications, open]);

  if (!notificationsEnabled) return <></>;

  return (
    <>
      <Tooltip title="Alerts">
        <Button className="menu-button" onClick={handleOpen}>
          <BellIcon />
          {expanded && <div>Alerts</div>}
          {!!notificationsCount && (
            <NotificationBadge className="notifications-badge">
              {notificationsCount}
            </NotificationBadge>
          )}
        </Button>
      </Tooltip>
      {open && (
        <Portal>
          <ClickAwayListener onClickAway={handleClose}>
            <Container ref={containerRef} menuExpanded={menuExpanded}>
              <Header>
                Alerts
                <CloseIcon onClick={handleClose} />
              </Header>
              <Wrapper>
                {data?.earningUpdateNotifications.map(
                  ({ company, notificationCount, seen, updatedAt }) => (
                    <Notification
                      key={company.id}
                      to={generatePath(routes.LIVE_UPDATES, {
                        companyId: company.id.toString()
                      })}
                      onClick={handleUpdateClick(company)}
                    >
                      <Tooltip title={company.name}>
                        <CompanyName>{company.name}</CompanyName>
                      </Tooltip>
                      <Tooltip title={''}>
                        <Sections></Sections>
                      </Tooltip>
                      <Tooltip title={formatDate(updatedAt)}>
                        <Timestamp>{formatDate(updatedAt)}</Timestamp>
                      </Tooltip>
                      {notificationCount > 0 && <span>{notificationCount} updates</span>}
                      {!seen && <div className="badge" />}
                    </Notification>
                  )
                )}
              </Wrapper>
            </Container>
          </ClickAwayListener>
        </Portal>
      )}
    </>
  );
};
