import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CircularProgress from '@material-ui/core/CircularProgress';
import InfoIcon from '@material-ui/icons/Info';
import useLocal from 'localization';

import Button from '@material-ui/core/Button';
import Props from './types';
import useStyles from './style';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { setDownloadModelData, toggleDialog } from 'store';
import { DIALOG_IDS } from 'domain/models';

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(even)': {
      backgroundColor: theme.palette.action.hover
    }
  }
}))(TableRow);
const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.headingBG.main,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(3),
    minWidth: '30%'
  },
  body: {
    fontSize: 14,
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    minWidth: '30%'
  }
}))(TableCell);

const ModelsTable: React.FC<Props> = ({
  clientModels,
  onSubscribeModel,
  subscriptionPendingModel,
  showNotAllowed,
  noCredit,
  isTrialTier
}: Props) => {
  const classes = useStyles();
  const name = useLocal('name');
  const action = useLocal('action');
  const download = useLocal('download');
  const subscribe = useLocal('subscribe');
  const unsubscribe = useLocal('unsubscribe');

  const lastUpdatedLable = useLocal('last_updated');
  const dispatch = useDispatch();

  return (
    <TableContainer component={Paper}>
      <Table data-testid="model-table">
        <TableHead>
          <TableRow>
            <StyledTableCell width="60%">{name}</StyledTableCell>
            <StyledTableCell>{lastUpdatedLable}</StyledTableCell>
            <StyledTableCell align="right">{action}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientModels &&
            clientModels.map(({ company, latestQuarter, id, isSubscribed }, index) => {
              const { name, ticker } = company;
              return (
                <StyledTableRow
                  key={`${company.id}-${id}-${isSubscribed}-${id === subscriptionPendingModel}`}
                  data-testid="model-table-item"
                >
                  <StyledTableCell>
                    <a
                      rel="noreferrer"
                      target="_blank"
                      className={classes.modelPreviewLink}
                      href={`${process.env.REACT_APP_BACKEND_URL}/preview/${id}`}
                      data-testid="model-name"
                    >
                      <InfoIcon />
                    </a>
                    {ticker}-{name}
                  </StyledTableCell>
                  <StyledTableCell>{latestQuarter}</StyledTableCell>
                  <StyledTableCell align="right">
                    {isSubscribed && (
                      <Button
                        color="primary"
                        data-testid="download-model-btn"
                        onClick={() => {
                          dispatch(setDownloadModelData({ companyTicker: ticker, modelId: id }));
                          dispatch(toggleDialog({ action: 'open', id: DIALOG_IDS.DOWNLOAD_MODEL }));
                        }}
                      >
                        {download}
                      </Button>
                    )}

                    {!isSubscribed && (
                      <Button
                        color="primary"
                        disabled={subscriptionPendingModel === id || isTrialTier}
                        data-testid="subscribe-model-btn"
                        onClick={() => {
                          if (onSubscribeModel)
                            onSubscribeModel(company.id, id, index, company?.ticker);
                        }}
                      >
                        {subscribe}
                        {subscriptionPendingModel === id && <CircularProgress size={24} />}
                      </Button>
                    )}
                    {isSubscribed && !isTrialTier && (
                      <Button data-testid="unsubscribe-model-btn" className={classes.unSubscribe}>
                        {unsubscribe}
                      </Button>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              );
            })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default React.memo(ModelsTable);
