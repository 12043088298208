import { HttpMethod } from 'application/protocols';
import { httpClient } from 'infra/http';
import { useEffect, useState } from 'react';

export const useRemoteHTMLDocument = (url: string, skip = false) => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);
  const [content, setContent] = useState<string>('');

  useEffect(() => {
    setError('');
    setContent('');
    if (skip) {
      setLoading(false);
      return;
    }
    setLoading(true);
    httpClient
      .request({
        withCredentials: true,
        method: HttpMethod.GET,
        url,
        headers: {}
      })
      .then((response) => {
        let content = (response as { data?: string })?.data ?? '';
        if (content) {
          content = content.replaceAll('/static/', `${process.env.REACT_APP_BACKEND_URL}/static/`);
          // Update anchor tag navigation to use about:srcdoc so it does not trigger a full page reload
          content = content.replaceAll(/href="(#[^"]+)"/gim, (_, href) => {
            return `href="about:srcdoc${href}"`;
          });

          // Update external links to have the current page origin instead of the remote host
          content = content.replaceAll(/href="https?:\/\/[^/"]+([^"]*)"/gim, (_, path = '') => {
            return `href="${window.location.origin}${path}"`;
          });
          setContent(content);
        }
      })
      .catch(setError)
      .finally(() => {
        setLoading(false);
      });
  }, [skip, url]);

  return { error, loading, content };
};
