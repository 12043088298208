import { Fundamental } from 'domain/models';
import Formula from 'fparser';
export const ALPHABETS = [...Array(26).keys()].map((i: number) =>
  String.fromCharCode(i + 97).toUpperCase()
);

export const ROLLED_UP_NUMBER_FORMULA_REGEX = /C_(?<constant>\d*\.?\d+)+|(?<seriesId>\d+)/gim;

export const getValue = (fundamental: Fundamental, isYTD: boolean) =>
  parseFloat(isYTD ? fundamental?.value ?? 0 : fundamental?.normalizedValue ?? 0);

export const getSeriesIdsFromFormula = (formula: string) => {
  const numbers = [];
  const matches = formula.matchAll(ROLLED_UP_NUMBER_FORMULA_REGEX);
  for (const match of matches) {
    if (match.groups?.seriesId) {
      numbers.push(Number(match.groups.seriesId));
    }
  }
  return numbers;
};

export const formatFormula = (formula: string) => {
  let newFormula = String(formula);
  let replaceIndex = 0;
  newFormula = newFormula.replaceAll(
    ROLLED_UP_NUMBER_FORMULA_REGEX,
    (substring, constant, seriesId) => {
      if (seriesId) {
        replaceIndex += 1;
        return `[${ALPHABETS[replaceIndex - 1]}${substring}]`;
      } else if (constant) {
        return constant;
      }
    }
  );
  return newFormula;
};

export const getFormulaObject = (formulaString: string) => {
  try {
    const formulaObject = new Formula(formulaString);
    return formulaObject;
  } catch {
    return;
  }
};

export const getOperators = (formula?: Formula): string[] => {
  if (!formula) return [];
  const operators: string[] = [];
  const visited = new Set<any>();

  const traverse = (node: any): void => {
    if (!node || typeof node !== 'object' || visited.has(node)) return;
    visited.add(node);

    // For well-formed binary operations, use in-order traversal.
    if (node.left && node.right) {
      traverse(node.left);
      if (node.operator) {
        operators.push(node.operator);
      }
      traverse(node.right);
      return;
    }

    // For nodes with argument lists (e.g. functions or unary expressions)
    if (Array.isArray(node.args)) {
      // If there's an operator on the parent node, add it first.
      if (node.operator) operators.push(node.operator);
      node.args.forEach(traverse);
    } else {
      // If it's a single-node operator.
      if (node.operator) operators.push(node.operator);
    }

    // Fallback: iterate over other keys in case there are nested objects.
    Object.keys(node).forEach((key) => {
      if (['operator', 'left', 'right', 'args'].includes(key)) return;
      const child = node[key];
      if (child && typeof child === 'object') {
        traverse(child);
      }
    });
  };

  traverse(formula);
  return operators;
};

export const evaluate = (
  formulaObject: Formula,
  fundamentalsBySeriesId: { [id: string]: Fundamental[] },
  seriesIds: number[],
  isYTD: boolean
): number => {
  const vars: { [key: string]: number } = {};
  seriesIds.forEach((seriesId, index) => {
    vars[`${ALPHABETS[index]}${seriesId}`] = getValue(fundamentalsBySeriesId[seriesId][0], isYTD);
  });
  return Number(formulaObject.evaluate(vars));
};

export const formatNumber = (number: number): string => {
  if (Number.isInteger(number)) {
    return number.toString();
  } else {
    return number.toPrecision(3);
  }
};
