import JSCookie from 'js-cookie';

import {
  CookieAttributes,
  CookieStorage,
  SESSION_COOKIE_NAME,
  SESSION_COOKIE_NAME_DEV
} from 'application/protocols';

class Cookie implements CookieStorage {
  get(key: string) {
    return JSCookie.get(key);
  }

  set(key: string, value?: string | Record<string, unknown>, options?: CookieAttributes) {
    if (!value) {
      JSCookie.remove(key, options);
      return;
    }

    JSCookie.set(key, value, options);
  }

  getSessionCookie = () => {
    if (process.env.REACT_APP_ENV === 'production') return JSCookie.get(SESSION_COOKIE_NAME);
    return JSCookie.get(SESSION_COOKIE_NAME_DEV);
  };

  removeSessionCookie = () => {
    if (process.env.REACT_APP_ENV === 'production') {
      JSCookie.remove(SESSION_COOKIE_NAME);
    } else {
      JSCookie.remove(SESSION_COOKIE_NAME_DEV);
    }
    JSCookie.remove('accessToken');
  };
}

export const Cookies = new Cookie();
