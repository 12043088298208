import { Menu as MetrialMenu } from '@material-ui/core/';
import MenuItem from '@material-ui/core/MenuItem';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { DIALOG_IDS } from 'domain/models';
import useLocal from 'localization';
import { SET_SHOW_TRANSLATION_KEYS } from 'localization/reducer';
import { routes } from 'main/router';
import { useAuthServices, useSelectors } from 'presentation/hooks';
import queryString from 'query-string';
import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { toggleDialog } from 'store/slices';
import { Mixpanel } from 'infra/mixpanel';
import useStyles from './style';

const Menu = () => {
  const location = useLocation();

  const dispatch = useDispatch();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<Element | null>(null);

  const { isAuthenticated } = useSelectors.Auth();

  const modelText = useLocal('model');
  const kpiKey = useLocal('kpi');
  const supportText = useLocal('support');

  const logoutText = useLocal('logout');
  const loginText = useLocal('login');
  const documentationText = useLocal('documentation');

  const [logout] = useAuthServices().logout();

  const onAccountLinkClick = (event: React.MouseEvent) => {
    Mixpanel.track('marketplace:menu:account_menu:click');
    setAnchorEl(event.currentTarget);
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const onModelsClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:models:click');
  }, []);

  const onSupportClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:support:click');
    window.open('mailto:support@daloopa.com');
  }, []);

  const onKPILinkClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:kpi_link:click');
  }, []);

  const onDocumentationLinkClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:documentation_link:click');
  }, []);

  const onLogoutClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:logout:click');
    logout();
  }, [logout]);

  const onLoginClick = useCallback(() => {
    Mixpanel.track('marketplace:menu:login:click');

    handleClose();
    dispatch(toggleDialog({ action: 'open', id: DIALOG_IDS.LOGIN }));
  }, [dispatch, handleClose]);

  useEffect(() => {
    const parsed = queryString.parse(location.search);
    if (parsed['showTranslationKeys'] === 'true') {
      dispatch({
        type: SET_SHOW_TRANSLATION_KEYS,
        showTranslationKeys: true
      });
    } else {
      dispatch({
        type: SET_SHOW_TRANSLATION_KEYS,
        showTranslationKeys: false
      });
    }
  }, [dispatch, location.search]);

  return (
    <Tabs value={location.pathname !== routes.HOME ? location.pathname : false}>
      <Tab
        label={modelText}
        value={routes.MODELS}
        component={Link}
        to={routes.MODELS}
        className={classes.menuLink}
        data-testid="model-page-link"
        onClick={onModelsClick}
      />
      <Tab label={supportText} className={classes.menuLink} onClick={onSupportClick} />
      <Tab
        label={kpiKey}
        component="a"
        href={process.env.REACT_APP_KPI_LINK}
        className={classes.menuLink}
        data-kpi="kpi-link"
        target="_blank"
        onClick={onKPILinkClick}
      />
      <Tab
        label={documentationText}
        component="a"
        href={process.env.REACT_APP_HELP_PAGE_LINK}
        className={classes.menuLink}
        data-testid="help-page-link"
        target="_blank"
        onClick={onDocumentationLinkClick}
      />
      <Tab className={classes.iconLink} icon={<AccountCircleIcon />} onClick={onAccountLinkClick} />
      <MetrialMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {isAuthenticated && <MenuItem onClick={onLogoutClick}>{logoutText}</MenuItem>}
        {!isAuthenticated && <MenuItem onClick={onLoginClick}>{loginText}</MenuItem>}
      </MetrialMenu>
    </Tabs>
  );
};
export default Menu;
