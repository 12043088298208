import { Button, styled } from '@mui/material';
import { Mixpanel } from 'infra/mixpanel';
import { RedAlertImage } from 'presentation/components';
import React from 'react';

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: '55px',
  margin: '10vh auto 0',
  textAlign: 'center',
  color: '#000000DE',

  '& button': {
    color: '#000000DE',
    borderColor: '#000000DE'
  }
});

export const UpdatesPageError = () => {
  const handleSupportClick = () => {
    Mixpanel.track('marketplace:updates_page:contact_support_clicked');
  };
  return (
    <Container>
      <RedAlertImage />
      We’re having trouble loading the update preview. Please refresh the page or try again later.
      <br />
      If the issue continues, contact our support team for assistance
      <a
        href={`mailto:${process.env.REACT_APP_DALOOPA_SUPPORT_EMAIL}`}
        target="_blank"
        rel="noreferrer"
      >
        <Button variant="outlined" size="small" onClick={handleSupportClick}>
          contact support
        </Button>
      </a>
    </Container>
  );
};
