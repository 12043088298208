import { useStatsigClient } from '@statsig/react-bindings';
import { useSelectors } from 'presentation/hooks';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUserData } from 'store/slices';

type Props = {
  children: React.ReactNode;
};

export const FeatureFlagValidationProvider = ({ children }: Props) => {
  const dispatch = useDispatch();

  const { checkGate } = useStatsigClient();

  const { isAuthenticated } = useSelectors.Auth();

  useEffect(() => {
    if (isAuthenticated) {
      const isAnalyst = checkGate('is_user_analyst');
      dispatch(setUserData({ isAnalyst }));
    } else {
      dispatch(setUserData({ isAnalyst: false }));
    }
  }, [checkGate, dispatch, isAuthenticated]);

  return <>{children}</>;
};
