import React from 'react';
import { styled, Tooltip as MUITooltip, TooltipProps, tooltipClasses } from '@mui/material';

const StyledTooltip = styled(({ className, ...props }: TooltipProps) => (
  <MUITooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: '12px',
    fontWeight: 'bold',
    whiteSpace: 'pre-wrap'
  }
});

// This component is temporary until we can remove MUI v4 and use v5 theme provider
export const Tooltip = (props: TooltipProps) => <StyledTooltip {...props} arrow />;
