import React from 'react';
import { Navigate, NavigateProps, useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';

export const NavigateWithParams = (props: NavigateProps) => {
  const params = useParams();
  const [search] = useSearchParams();

  // Replace parameter placeholders, allowing for optional params (e.g. :param?)
  const to = props.to.toString().replace(/:([a-zA-Z0-9]+)\??/g, (_, key) => params[key] ?? '');

  const searchParams = new URLSearchParams(search);

  if (searchParams.get('v1') === 'true') {
    searchParams.delete('v1');
    window.location.assign(to.concat('?').concat(searchParams.toString()));
  }

  return <Navigate {...props} to={to.concat('?').concat(searchParams.toString())} />;
};
