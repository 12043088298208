import React from 'react';

export const ChevronLeftDouble = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(2,3)">
      <path
        d="M5.99988 1.5575L4.94238 0.5L0.442383 5L4.94238 9.5L5.99988 8.4425L2.56488 5L5.99988 1.5575Z"
        fill="black"
        fillOpacity="0.87"
      />
      <path
        d="M11.5575 1.5575L10.5 0.5L6 5L10.5 9.5L11.5575 8.4425L8.1225 5L11.5575 1.5575Z"
        fill="black"
        fillOpacity="0.87"
      />
    </g>
  </svg>
);
