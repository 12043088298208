import { DIALOG_IDS } from 'domain/models';
import { Mixpanel } from 'infra/mixpanel';
import { routes } from 'main/router';
import {
  BookIcon,
  DaloopaLogoIcon,
  DaloopaPlusLogoIcon,
  HomeIcon,
  InstanceIcon,
  ListIcon,
  LoginIcon,
  LogoutIcon,
  MailIcon,
  MenuCollapseIcon,
  MenuExpandIcon,
  SettingsIcon,
  Tooltip,
  UserNotifications
} from 'presentation/components';
import { useAuthServices, useSelectors } from 'presentation/hooks';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { setMenuState } from 'store';
import { toggleDialog } from 'store/slices';
import {
  Container,
  ContentWrapper,
  MenuHeader,
  MenuMainNavigation,
  MenuSubNavigation,
  OldMarketPlaceButton
} from './Menu.styles';
import { Button } from '@mui/material';

export const Menu = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { menuExpanded } = useSelectors.General();
  const { isAuthenticated } = useSelectors.Auth();
  const { productType, isEnterpriseTier, isTrialTier } = useSelectors.User();

  const [logout] = useAuthServices().logout();

  const [expanded, setExpanded] = useState(menuExpanded);

  const isPlus = useMemo(
    () => productType === 'daloopa_plus' || productType === 'daloopa_plus_time_ltd',
    [productType]
  );

  const isEnterprise = isEnterpriseTier || isTrialTier;

  const handleToggleMenu = () => {
    setExpanded((prev) => {
      const state = !prev;

      Mixpanel.track('marketplace:menu:toggle_state', { state });

      dispatch(setMenuState(state));
      return state;
    });
  };

  const handleHomeClick = () => {
    Mixpanel.track('marketplace:menu:home');
  };
  const handleLoginClick = () => {
    Mixpanel.track('marketplace:menu:login');

    dispatch(toggleDialog({ action: 'open', id: DIALOG_IDS.LOGIN }));
  };
  const handleLogout = () => {
    Mixpanel.track('marketplace:menu:logout');
    logout();
  };

  const handleIndustryModels = () => {
    Mixpanel.track('marketplace:menu:industry_models');
    navigate(routes.INDUSTRY);
  };

  const handleSettings = () => {
    Mixpanel.track('marketplace:menu:settings');
    navigate(routes.SETTINGS);
  };

  const handleDocumentationClick = () => {
    Mixpanel.track('marketplace:menu:documentation');
  };
  const handleSupportClick = () => {
    Mixpanel.track('marketplace:menu:support');
  };

  const handleOldMarketplaceClick = () => {
    Mixpanel.track('marketplace:menu:old_marketplace');
  };

  return (
    <Container open={expanded}>
      <ContentWrapper>
        <MenuHeader>
          {expanded && (
            <Link to={routes.HOME} onClick={handleHomeClick}>
              {!isPlus && <DaloopaLogoIcon className="daloopa-icon" />}
              {isPlus && <DaloopaPlusLogoIcon className="daloopa-icon" />}
            </Link>
          )}
          <Tooltip title={expanded ? 'Collapse' : 'Expand'}>
            <Button className="menu-button menu-toggle" onClick={handleToggleMenu}>
              {expanded ? <MenuCollapseIcon /> : <MenuExpandIcon />}
            </Button>
          </Tooltip>
        </MenuHeader>
        <MenuMainNavigation>
          <Link to={routes.HOME} onClick={handleHomeClick}>
            <Tooltip title="Home">
              <Button className="menu-button" onClick={(e) => e.currentTarget.blur()}>
                <HomeIcon />
                {expanded && <div>Home</div>}
              </Button>
            </Tooltip>
          </Link>
          {isAuthenticated && isEnterprise && (
            <Tooltip title="Industry Models">
              <Button className="menu-button" onClick={handleIndustryModels}>
                <ListIcon />
                {expanded && <div>Industry Models</div>}
              </Button>
            </Tooltip>
          )}
          {process.env.REACT_APP_HELP_PAGE_LINK && (
            <a
              href={process.env.REACT_APP_HELP_PAGE_LINK}
              target="_blank"
              rel="noreferrer"
              onClick={handleDocumentationClick}
            >
              <Tooltip title="Documentation">
                <Button className="menu-button">
                  <BookIcon />
                  {expanded && <div>Documentation</div>}
                </Button>
              </Tooltip>
            </a>
          )}
        </MenuMainNavigation>
        <MenuSubNavigation>
          <a
            href={`mailto:${process.env.REACT_APP_DALOOPA_SUPPORT_EMAIL}`}
            target="_blank"
            rel="noreferrer"
            onClick={handleSupportClick}
          >
            <Tooltip title="Support">
              <Button className="menu-button">
                <MailIcon />
                {expanded && <div>Support</div>}
              </Button>
            </Tooltip>
          </a>
          {isAuthenticated && (
            <>
              <UserNotifications expanded={expanded} />
              <Tooltip title="Settings">
                <Button className="menu-button" onClick={handleSettings}>
                  <SettingsIcon />
                  {expanded && <div>Settings</div>}
                </Button>
              </Tooltip>
              <Tooltip title="Logout">
                <Button className="menu-button" onClick={handleLogout}>
                  <LogoutIcon />
                  {expanded && <div>Logout</div>}
                </Button>
              </Tooltip>
            </>
          )}
          {!isAuthenticated && (
            <Tooltip title="Login">
              <Button className="menu-button" onClick={handleLoginClick}>
                <LoginIcon />
                {expanded && <div>Login</div>}
              </Button>
            </Tooltip>
          )}
          <Tooltip title="Switch to old Marketplace">
            <OldMarketPlaceButton
              href={`${routes.HOME}?v1=true`}
              target="_blank"
              rel="noreferrer"
              onClick={handleOldMarketplaceClick}
            >
              <InstanceIcon />
              {expanded && <span>Switch to old Marketplace</span>}
            </OldMarketPlaceButton>
          </Tooltip>
        </MenuSubNavigation>
      </ContentWrapper>
    </Container>
  );
};
