import React, { useMemo } from 'react';
import { CircularProgress } from '@mui/material';
import { ProviderComposition } from 'main/providers/ProviderComposition';
import { Menu, ModelSearchInput } from 'presentation/components';
import { useSelectors } from 'presentation/hooks';
import { Outlet, useLocation } from 'react-router';
import { Container, Wrapper } from './DefaultLayout.styles';
import { routes } from 'main/router';
import { useSearchParams } from 'react-router-dom';

type Props = {
  children?: React.ReactNode;
};

export const DefaultLayout = ({ children }: Props) => {
  const location = useLocation();
  const [search] = useSearchParams();

  const isAddinView = search.get('excel_plugin') === 'true';

  const { isAuthenticated } = useSelectors.Auth();
  const { menuExpanded } = useSelectors.General();
  const { tier } = useSelectors.User();

  const isErrorRoute = useMemo(() => {
    const errorRoutes = [routes.ERROR_401, routes.ERROR_403, routes.ERROR_404, routes.ERROR_500];
    return errorRoutes.some((path) => location.pathname.startsWith(path));
  }, [location.pathname]);

  const isLoading = useMemo(() => !isErrorRoute && isAuthenticated && !tier, [
    isAuthenticated,
    isErrorRoute,
    tier
  ]);

  const hideSearch = useMemo(() => {
    const routesWithoutSearch = [
      routes.DOCUMENT,
      routes.SETTINGS,
      routes.LIVE_UPDATES,
      routes.SOURCE
    ].map((path) => path.split('/:')[0]);
    return routesWithoutSearch.some((path) => location.pathname.startsWith(path));
  }, [location.pathname]);

  const noPadding = useMemo(() => {
    const routesWithoutPadding = [routes.DOCUMENT, routes.SOURCE].map(
      (path) => path.split('/:')[0]
    );
    return routesWithoutPadding.some((path) => location.pathname.startsWith(path));
  }, [location.pathname]);

  if (isAddinView) {
    return (
      <ProviderComposition>
        <Container menuExpanded={false} noPadding>
          <Wrapper noPadding>
            {isLoading && <CircularProgress size={16} style={{ margin: '32px auto' }} />}
            {!isLoading && <Outlet />}
          </Wrapper>
        </Container>
      </ProviderComposition>
    );
  }

  return (
    <ProviderComposition>
      <Menu />
      <Container menuExpanded={menuExpanded} noPadding={noPadding}>
        {!hideSearch && <ModelSearchInput />}
        <Wrapper noPadding={noPadding}>
          {isLoading && <CircularProgress size={16} style={{ margin: '32px auto' }} />}
          {!isLoading && (
            <>
              {children}
              <Outlet />
            </>
          )}
        </Wrapper>
      </Container>
    </ProviderComposition>
  );
};
