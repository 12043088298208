import React from 'react';

import { useModelServices, useParams } from 'presentation/hooks';
import {
  ActionButton,
  CompanyName,
  Container,
  Actions,
  Details,
  PageTitle
} from './LiveUpdatesPageHeader.styles';
import { DownloadIcon, ModelStatusPill } from 'presentation/components';
import { Mixpanel } from 'infra/mixpanel';
import { useDispatch } from 'react-redux';
import { setDownloadModelData, toggleDialog } from 'store';
import { DIALOG_IDS } from 'domain/models';
import { CompanyDetailsService } from 'application/services';

type Params = CompanyDetailsService.Response['company'] | undefined;

export const LiveUpdatesPageHeader = (company: Params) => {
  const dispatch = useDispatch();

  const { companyId } = useParams<'LIVE_UPDATES'>();

  const { disabled, isSubscribed, modelId, name, status, ticker } = company ?? {};

  const [subscribeModel, { loading: subscribing }] = useModelServices().subscribeModel();

  const handleDownload = () => {
    if (!modelId || !ticker) return;

    Mixpanel.track('marketplace:preview:download:click', {
      companyId,
      modelId,
      ticker
    });

    const download = () => {
      dispatch(setDownloadModelData({ companyTicker: ticker, modelId }));
      dispatch(toggleDialog({ action: 'open', id: DIALOG_IDS.DOWNLOAD_MODEL }));
    };

    if (!isSubscribed) {
      subscribeModel({ variables: { companyId } })?.then(download);
    } else {
      download();
    }
  };

  return (
    <Container>
      <Details>
        <PageTitle>Update preview</PageTitle>
        <CompanyName>
          {ticker} - {name}
        </CompanyName>
      </Details>
      <Actions>
        {status && <ModelStatusPill status={status} />}
        <ActionButton onClick={handleDownload} disabled={disabled || subscribing}>
          <DownloadIcon />
          Download
        </ActionButton>
      </Actions>
    </Container>
  );
};
