/* eslint-disable @typescript-eslint/no-explicit-any */
import { ModelType } from 'domain/models';
import { Mixpanel } from 'infra/mixpanel';

export const getFormattedDate = (date: string) => {
  const d = new Date(date);
  return d.toLocaleDateString('en-US', {
    year: '2-digit',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    hour12: true,
    minute: '2-digit'
  });
};

export const validatedModelDownload = async (
  url: string,
  modelId: string | number,
  modelType: ModelType,
  ticker?: string
) => {
  let res: Response | undefined;
  try {
    res = await fetch(url, {
      method: 'GET',
      redirect: 'manual',
      credentials: 'omit'
    });

    if (res?.ok) {
      Mixpanel.track('marketplace:model:download', {
        modelId,
        modelType,
        ticker
      });
      window.location.assign(url);
    } else {
      throw new Error(res.status?.toString());
    }
  } catch (error: any) {
    Mixpanel.track('marketplace:model:download-validation:failed', {
      modelId,
      modelType,
      ticker,
      error: res?.ok ? error?.message : error
    });
    throw new Error('Failed to download model. Please try again.');
  }
};
