import { ButtonGroup, styled } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%'
});

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',

  '& > span': {
    display: 'flex',
    gap: '0.5rem',
    margin: '32px auto'
  }
});

export const Header = styled('div')({
  position: 'relative',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '50px',
  borderBottom: '1px solid #EEEEEE',
  padding: '10px 12px 10px 42px',
  fontSize: '0.75rem',
  color: '#000000DE',

  '& > .breadcrumbs': {
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',

    '& > svg': {
      margin: '-2px 0.5rem'
    }
  }
});

export const InnerSideBarContainer = styled('div')<{
  collapsed?: boolean;
  fundamentalSelected?: boolean;
}>(({ collapsed, fundamentalSelected }) => ({
  display: 'flex',
  position: 'relative',
  width: fundamentalSelected ? '307px' : '614px',
  minWidth: fundamentalSelected ? '307px' : '614px',
  overflow: 'hidden',
  borderRight: '1px solid #E0E0E0',
  background: '#F5F5F5',

  transition: 'width 0.3s ease-in-out, min-width 0.3s ease-in-out',

  ...(collapsed && {
    width: 0,
    minWidth: 0
  }),

  '& > div': {
    display: 'flex',
    flexDirection: 'column',
    padding: '1.5rem',
    width: '100%',
    minWidth: fundamentalSelected ? '307px' : '614px',
    height: '100%'
  }
}));

export const PageInfo = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  minWidth: 'fit-content',
  paddingBottom: '0.25rem',
  fontSize: '0.75rem',
  fontWeight: 700,

  '& > span': {
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    paddingBottom: '1.25rem',
    overflow: 'hidden',
    fontSize: '1.25rem',
    fontWeight: 500
  },

  '& > div': {
    marginTop: '4px',
    fontWeight: 400
  }
});

export const DocumentPlaceholder = styled('div')({
  margin: 'auto',
  color: '#60646C'
});

export const ToggleInnerButton = styled('div')<{ collapsed: boolean }>(({ collapsed }) => ({
  position: 'absolute',
  top: 16,
  right: 16,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: '30px',
  height: '30px',
  cursor: 'pointer',

  '& > svg': {
    fontSize: '1.125rem',

    transform: collapsed ? 'rotate(180deg)' : 'rotate(0deg)',
    transition: 'transform 0.3s ease-in-out'
  }
}));

export const StyledButtonGroup = styled(ButtonGroup)({
  width: '100%',
  maxWidth: '260px',

  '& > .MuiButtonGroup-grouped': {
    '&[data-active=true]': {
      pointerEvents: 'none'
    },

    width: '100%',
    borderColor: '#0000001F',
    fontWeight: 500,

    '&:not(:last-of-type)': {
      marginRight: '1px',

      '&:hover': {
        borderRightColor: '#1976d2'
      }
    }
  }
});
