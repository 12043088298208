import {
  ApolloClient,
  ApolloLink,
  from,
  HttpLink,
  InMemoryCache,
  ServerError
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { AuthTokenError, SESSION_COOKIE_NAME } from 'application/protocols';
import { Cookies } from 'infra/storage';
import { router, routerV1 } from 'main/router';
import { loggedOut, store } from 'store';
import { getBaseUrl } from '../utils';

const CSRFTOKEN = 'csrftoken';

const getHeaders = () => {
  const csrfToken = Cookies.get(CSRFTOKEN);

  const headers: { 'X-CSRFToken'?: string; Authorization?: string } = {};

  if (csrfToken) {
    headers['X-CSRFToken'] = csrfToken;
  }

  return headers;
};

const authLink = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers }: { headers: Record<string, string> }) => {
    return {
      headers: {
        ...headers,
        ...getHeaders()
      }
    };
  });

  return forward(operation);
});

// Log any GraphQL errors or network error that occurred
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.error(
        `[Marketplace] GraphQL error: Message: ${message}, Location: ${JSON.stringify(
          locations
        )}, Path: ${path}`
      );
      if (message === AuthTokenError && Cookies.get(SESSION_COOKIE_NAME)) {
        Cookies.removeSessionCookie();
        store.dispatch(loggedOut());
      }
    });
  }

  if (networkError) {
    console.error(`[Marketplace] Network error: ${networkError}`);

    if (networkError.name === 'ServerError' || networkError.name === 'ServerParseError') {
      const code = (networkError as ServerError).statusCode;

      switch (code) {
        case 404:
          router.navigate('/404');
          routerV1.navigate('/404');
          break;
        case 401:
          router.navigate('/401');
          routerV1.navigate('/401');
          break;
        case 403:
          router.navigate('/403');
          routerV1.navigate('/403');
          break;
        case 500:
          router.navigate('/500');
          routerV1.navigate('/500');
          break;
      }
    }
  }
});

const httpLink = new HttpLink({
  uri: `${getBaseUrl()}/gql`,
  credentials: 'include'
});

const link = from([errorLink, authLink, httpLink]);

export const gqlClient = new ApolloClient({
  link,
  cache: new InMemoryCache(),
  credentials: 'include'
});
