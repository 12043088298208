import { Button, styled } from '@mui/material';

export const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.75rem',
  width: '100%',
  minHeight: 'fit-content',
  justifyContent: 'space-between',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row',
    gap: '20px',
    alignItems: 'center'
  }
}));

export const Details = styled('div')({
  display: 'flex',
  flexDirection: 'column'
});

export const PageTitle = styled('span')({
  display: 'flex',
  color: '#000000',
  fontSize: '0.75rem',
  fontWeight: 700
});

export const CompanyName = styled('span')({
  display: 'flex',
  color: '#000000',
  fontSize: '1.25rem',
  fontWeight: 500
});

export const Actions = styled('div')({
  display: 'flex',
  gap: '1rem'
});

export const ActionButton = styled(Button)(() => ({
  gap: '8px',
  minWidth: 'unset',
  width: '115px',
  height: '30px',
  border: '1px solid #E8E8E8',
  borderRadius: '4px',
  background: '#E8E8E8',
  color: '#000000DE',
  fontSize: '12px',
  fontWeight: 'bold',
  lineHeight: 1,

  '&:hover': {
    background: '#F8F8F8'
  },

  '& svg': {
    minWidth: '18px',
    minHeight: '18px'
  }
}));
