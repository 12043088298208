import { Routes } from 'main/router';
import { useParams as useReactRouterParams } from 'react-router';

type ExtractRouteParams<
  T extends string
> = T extends `${infer _Start}:${infer Param}?/${infer Rest}`
  ? { [K in Param | keyof ExtractRouteParams<`/${Rest}`>]?: string }
  : T extends `${infer _Start}:${infer Param}?`
  ? { [K in Param]?: string }
  : T extends `${infer _Start}:${infer Param}/${infer Rest}`
  ? { [K in Param | keyof ExtractRouteParams<`/${Rest}`>]: string }
  : T extends `${infer _Start}:${infer Param}`
  ? { [K in Param]: string }
  : Record<string, string>;

type RouteParamsMap = {
  [K in keyof Routes]: ExtractRouteParams<Routes[K]>;
};

export const useParams = <T extends keyof RouteParamsMap>(): RouteParamsMap[T] => {
  return useReactRouterParams() as RouteParamsMap[T];
};
