import { gql } from '@apollo/client';

export const DOCUMENT_INFO_SERVICE = gql`
  query getDocumentInfo($documentId: Int!) {
    documentInfo(documentId: $documentId) {
      id
      reportedDate
      filingType
      documentType
      url
      __typename
      company {
        ticker
        name
        __typename
      }
    }
  }
`;

export namespace DocumentInfoService {
  export type Params = {
    documentId: string;
  };

  export type Response = {
    documentInfo: DocumentInfo;
  };

  type DocumentInfo = {
    id: string;
    reportedDate: string;
    filingType: string;
    documentType: string;
    url: string;
    __typename: string;
    company: {
      ticker: string;
      name: string;
      __typename: string;
    };
  };
}
