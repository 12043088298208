import React, { Fragment, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { FundamentalType } from 'domain/models';
import {
  ActionButton,
  CrosshairIcon,
  DocumentReportAnIssue,
  DocumentViewer,
  SideBar
} from 'presentation/components';
import { useSelectors, useSourceServices } from 'presentation/hooks';
import { Header, Wrapper } from './SourcePage.styles';
import { Mixpanel } from 'infra/mixpanel';
import { RestatementsTable } from './RestatementsTable';
import { CompositeSideBar, SideBarRef } from './CompositeSideBar';
import { FundamentalInfoService } from 'application/services';
import { SeriesHistoryTable } from './SeriesHistoryTable';

type Props = {
  data?: FundamentalInfoService.Response;
  fundamentalId: string;
  innerSideBarRef?: React.RefObject<SideBarRef>;
  isStitcher: boolean;
  loading: boolean;
  selectedFundamental?: string;
};

export const SourceContent = ({
  data,
  fundamentalId,
  innerSideBarRef,
  isStitcher,
  loading,
  selectedFundamental
}: Props) => {
  const compositeRef = useRef<SideBarRef>(null);
  const [search] = useSearchParams();

  const iframeRef = useRef<HTMLIFrameElement>(null);

  const isAddinView = search.get('excel_plugin') === 'true';

  const innerFundamentalId = search.get('fundamentalId') ?? '';

  const { isAnalyst } = useSelectors.User();

  const { data: innerFundamentalData, loading: innerLoading } = useSourceServices().fundamentalInfo(
    {
      variables: {
        fundamentalId: innerFundamentalId
      },
      skip: !innerFundamentalId
    }
  );

  const isComposite = data?.fundamentalInfo.type === FundamentalType.COMPOSITE;
  const isFiller = data?.fundamentalInfo.type === FundamentalType.FILLER_VALUE;
  const hasRestatements = !!data?.fundamentalInfo.restatedFundamentals.length;
  const id = isComposite ? innerFundamentalId : fundamentalId;

  const url = (() => {
    if (isStitcher) {
      return `/text_fundamental?${search.toString()}`;
    }
    if (isAnalyst) {
      const value = isComposite
        ? innerFundamentalData?.fundamentalInfo.value
        : data?.fundamentalInfo.value;
      return `/text_fundamental?id=${id}${value ? `&value=${value}` : ''}`;
    }
    return `/embed/source/${id}`;
  })();

  const handleLocateDatapoint = () => {
    Mixpanel.track('marketplace:src:locate', data?.fundamentalInfo);
    iframeRef?.current?.contentWindow?.postMessage('locate', '*');
  };

  useEffect(() => {
    if (data?.fundamentalInfo) {
      Mixpanel.track('marketplace:src:open', data?.fundamentalInfo);
    }
  }, [data?.fundamentalInfo]);

  if (loading || !data?.fundamentalInfo) {
    return <CircularProgress size={16} style={{ margin: ' 32px auto' }} />;
  }

  const fundamentalData = !!innerFundamentalId
    ? innerFundamentalData?.fundamentalInfo
    : data?.fundamentalInfo;

  const fundamentalAvailable =
    fundamentalData?.type !== FundamentalType.COMPOSITE &&
    fundamentalData?.type !== FundamentalType.FILLER_VALUE;

  const showSidebar = !isComposite || !!selectedFundamental;

  const breadcrumbs = (() => {
    if (!fundamentalData?.company) return [];

    const crumbs = [`${fundamentalData?.company.ticker} - ${fundamentalData?.company.name}`];

    fundamentalData?.fullSeriesName?.split(' | ').forEach((name) => {
      crumbs.push(name);
    });

    return crumbs;
  })();

  return (
    <>
      {!isAddinView && (
        <>
          {isComposite && (
            <CompositeSideBar ref={compositeRef} {...data?.fundamentalInfo?.company} />
          )}
          {showSidebar && (
            <SideBar
              id={id}
              company={fundamentalData?.company}
              document={fundamentalData?.document}
              type={fundamentalData?.type}
              iframeRef={iframeRef}
              innerSideBarRef={compositeRef ?? innerSideBarRef}
              loading={loading || innerLoading}
            >
              {!isStitcher && (
                <>
                  {!!data.fundamentalInfo?.seriesHistory?.length && (
                    <SeriesHistoryTable
                      history={data?.fundamentalInfo.seriesHistory}
                      fundamentalId={id}
                    />
                  )}
                  {hasRestatements && (
                    <RestatementsTable
                      fundamentalId={id}
                      referenceFundamental={data?.fundamentalInfo.referenceFundamental}
                      restatedFundamentals={data?.fundamentalInfo.restatedFundamentals}
                    />
                  )}
                </>
              )}
            </SideBar>
          )}
        </>
      )}
      {showSidebar && (
        <Wrapper>
          <Header>
            {isAddinView && <DocumentReportAnIssue id={fundamentalId} />}
            <span className="breadcrumbs">
              {breadcrumbs.map((crumb, index) => (
                <Fragment key={index}>
                  {!!index && (
                    <svg
                      width="8"
                      height="12"
                      viewBox="0 0 8 12"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.41 0L0 1.41L4.58 6L0 10.59L1.41 12L7.41 6L1.41 0Z"
                        fill="black"
                        fillOpacity="0.56"
                      />
                    </svg>
                  )}
                  {crumb}
                </Fragment>
              ))}
            </span>
            {!isStitcher && (
              <ActionButton bgLight onClick={handleLocateDatapoint}>
                <CrosshairIcon />
                Locate Datapoint
              </ActionButton>
            )}
          </Header>
          {isFiller && (
            <>
              <span>This document is not available yet.</span>
              {!!document && (
                <span>
                  Please try again later or check the filing here:
                  <a href={fundamentalData?.document.url} target="_blank" rel="noreferrer">
                    {fundamentalData?.document.documentType}
                  </a>
                </span>
              )}
            </>
          )}
          {fundamentalAvailable && (
            <DocumentViewer
              ref={iframeRef}
              url={url}
              title="datapoint-viewer"
              dataLoading={innerLoading}
            />
          )}
        </Wrapper>
      )}
    </>
  );
};
