import { useMutation } from '@apollo/client';
import {
  MODEL_SUBSCRIBE_SERVICE,
  MODEL_UNSUBSCRIBE_SERVICE,
  ModelSubscribeService,
  ModelUnsubscribeService,
  USER_DETAILS_SERVICE
} from 'application/services';
import { MODEL_SUBSCRIPTION_ERRORS } from 'domain/models';
import { Mixpanel } from 'infra/mixpanel';
import { useToastContext } from 'presentation/contexts';

export const useModelServices = () => {
  const toast = useToastContext();

  const useSubscribeModel = () =>
    useMutation<ModelSubscribeService.Response, ModelSubscribeService.Params>(
      MODEL_SUBSCRIBE_SERVICE,
      {
        refetchQueries: [USER_DETAILS_SERVICE],
        onError: (error, opt) => {
          Mixpanel.track('marketplace:models:activate_model:fail', {
            variables: opt?.variables
          });
          toast.error(error.message || 'Failed to subscribe to model');
        },
        onCompleted: ({ activateModel }, opt) => {
          if (!activateModel?.success) {
            const error = activateModel?.errors[0];

            const errorMessage = error ? MODEL_SUBSCRIPTION_ERRORS[error] : '';

            toast.error(errorMessage);

            Mixpanel.track('marketplace:models:activate_model:fail', {
              error,
              companyId: opt?.variables?.companyId
            });

            return { success: false, error };
          } else {
            Mixpanel.track('marketplace:models:activate_model', {
              companyId: opt?.variables?.companyId
            });
          }
        }
      }
    );
  const useUnsubscribeModel = () =>
    useMutation<ModelUnsubscribeService.Response, ModelUnsubscribeService.Props>(
      MODEL_UNSUBSCRIBE_SERVICE,
      {
        refetchQueries: [USER_DETAILS_SERVICE],
        onError: (error) => {
          Mixpanel.track('marketplace:models:unsubscribe_model:fail', {
            error
          });
          toast.error(error.message || 'Something went wrong. Please try again.');
        },
        onCompleted: ({ unsubscribeModel }, clientOptions) => {
          if (!unsubscribeModel?.success) {
            const error = unsubscribeModel?.errors[0];

            const errorMessage = error ? MODEL_SUBSCRIPTION_ERRORS[error] : '';

            toast.error(errorMessage);

            const { companyId } = (clientOptions?.variables ?? {}) as ModelSubscribeService.Params;

            Mixpanel.track('marketplace:models:unsubscribe_model:fail', {
              error,
              companyId
            });

            return { success: false, error };
          } else {
            Mixpanel.track('marketplace:models:unsubscribe_model', {
              companyId: clientOptions?.variables?.companyId
            });
          }
        }
      }
    );

  return {
    subscribeModel: useSubscribeModel,
    unsubscribeModel: useUnsubscribeModel
  };
};
