export const routes = {
  /**
   * Updated Routes
   */
  HOME: '/',
  SEARCH: '/search',
  MODELS: '/models',
  SETTINGS: '/settings',
  INDUSTRY: '/industry-models',
  LIVE_UPDATES: '/updates/:companyId',
  SOURCE: '/src/:id?',
  DOCUMENT: '/document/:id',

  /**
   * Legacy Routes
   */
  TEXT_FUNDAMENTAL: '/text-fundamental',
  LIVE_PREVIEW: '/preview/:companyId',
  ROLLUP_NUMBERS: '/rollup',

  /**
   * Auth Routes
   */
  SIGN_UP: '/sign-up',
  VERIFY_EMAIL: '/verify-email',
  RESET_PASSWORD: '/reset-password',

  /**
   * Error Pages
   */
  ERROR_404: '/404',
  ERROR_403: '/403',
  ERROR_401: '/401',
  ERROR_500: '/500'
} as const;

export type Routes = typeof routes;

export const openRoutesList: string[] = [routes.SEARCH];
