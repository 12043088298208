import { routes } from 'main/router';
import { useEffect, useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';

const DEFAULT_TITLE = 'Daloopa - AI driven data extraction';

const TITLES: Partial<Record<keyof typeof routes, string>> = {
  HOME: 'Home',
  INDUSTRY: 'Industry Models',
  LIVE_UPDATES: 'Update Preview',
  SEARCH: 'Search',
  SETTINGS: 'Settings',
  SOURCE: 'Source',
  LIVE_PREVIEW: 'Live Preview'
};

export const usePageTitle = (prefix = '') => {
  const { pathname } = useLocation();

  const route = useMemo(() => {
    return Object.entries(routes).find(([_, value]) =>
      matchPath({ path: value, end: true }, pathname)
    )?.[0] as keyof typeof routes;
  }, [pathname]);

  const title = useMemo(() => {
    let content = prefix;
    const page = TITLES[route] ?? '';

    if (page) {
      if (prefix) {
        content = [content, page].join(' - ');
      } else {
        content = page;
      }
    }
    return [content, DEFAULT_TITLE].join(' | ');
  }, [prefix, route]);

  useEffect(() => {
    if (title !== document.title) {
      document.title = title;
    }
  }, [title]);
};
