import { gql } from '@apollo/client';

// TODO: We should no longer receive the token and refreshToken from the login service
// The token will be needed until we move the document rendering logic from the backend to the frontend

export const LOGIN_SERVICE = gql`
  mutation tokenAuthMutation($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      errors
      refreshToken
      success
      token
    }
  }
`;

export namespace LoginService {
  export type Params = {
    email: string;
    password: string;
  };
  export type Response = {
    tokenAuth: {
      errors: string[];
      refreshToken: string;
      success: boolean;
      token: string;
    };
  };
}
