import { useEffect, useRef, useState } from 'react';

export const useDebounce = <T extends string | number | string[]>(value: T, delay = 500) => {
  const timer = useRef<ReturnType<typeof setTimeout>>();

  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    timer.current = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      if (timer.current) {
        clearTimeout(timer.current);
      }
    };
  }, [value, delay]);

  return debouncedValue;
};
