import React, { useMemo, useRef } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';

import CircularProgress from '@material-ui/core/CircularProgress';
import useLocal from 'localization';

import ModelItem from 'components/ModelItem';
import ModelsTable from 'components/ModelsTable';

import Props from './types';
import { useDispatch, useSelector } from 'react-redux';
import { modelsSelectors } from '../../pages/Models/duck';
import { useTheme } from '@mui/material';
import { useMediaQuery } from '@material-ui/core';
import { AutoSizer, Grid, WindowScroller, GridCellProps } from 'react-virtualized';
import { clientModels, updateNotification } from '../../pages/Models/duck/types';
import useStyles from './style';
import { isEmpty } from 'lodash';
import { Mixpanel } from 'infra/mixpanel';
import { useSelectors } from 'presentation/hooks';
import { setDownloadModelData, toggleDialog } from 'store';
import { DIALOG_IDS } from 'domain/models';

const renderItem = ({
  columnIndex,
  rowIndex,
  onSubscribeModel,
  onUnSubscribeModel,
  subscriptionPendingModel,
  numberOfColumns,
  clientModels,
  isTrialTier,
  noCredit,
  earningsUpdateNotificationByCompanyId,
  style,
  showNotAllowed,
  handleDownload
}: GridCellProps &
  Props & {
    numberOfColumns: number;
    noCredit: boolean;
    earningsUpdateNotificationByCompanyId: { [key: string]: updateNotification };
    style: React.CSSProperties;
    showNotAllowed: boolean;
    handleDownload: (item: clientModels) => () => void;
  }) => {
  const index = rowIndex * numberOfColumns + columnIndex;
  const item = (clientModels ?? [])[index];
  if (!item) {
    return <></>;
  }

  return (
    <ModelItem
      style={style}
      onSubscribeModel={onSubscribeModel}
      onUnSubscribeModel={onUnSubscribeModel}
      showSubscribe={!item.isSubscribed}
      showNotAllowed={showNotAllowed}
      noCredit={noCredit}
      showDownload={item.isSubscribed}
      isTrialTier={isTrialTier}
      clientModelIndex={index}
      key={`${item.id}-${item.isSubscribed}-${subscriptionPendingModel === item.id}`}
      subscriptionPendingModel={subscriptionPendingModel}
      {...item}
      title={`${item.company.ticker}-${item.company.name}`}
      companyEarningsUpdate={earningsUpdateNotificationByCompanyId[item.company.id]}
      onDownload={handleDownload(item)}
    />
  );
};

const calculateItemWidth = (width: number, columnCount: number) => {
  return width / columnCount;
};

const ModelList: React.FC<Props> = (props: Props) => {
  const {
    industry,
    clientModels,
    showList,
    onSubscribeModel,
    onUnSubscribeModel,
    numberOfModelsInIndustry,
    numberOfIndustriesByDefault,
    loadMore,
    isIndustryModelLoading,
    subscriptionPendingModel,
    isFreeTier,
    maxSubscriptions,
    subscribedCount,
    isTrialTier
  } = props;

  const loadMoreLabel = useLocal('load_more');
  const clientModelsNotDisabled = clientModels?.filter((model) => !model?.company?.disabled);
  const theme = useTheme();
  const onlyExtraSmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
  const onlySmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const numberOfColumns = onlyExtraSmallScreen || onlySmallScreen ? 1 : 3;
  const containerRef = useRef<HTMLDivElement>(null);
  const containerWidth =
    containerRef?.current?.clientWidth ?? document.getElementById('content-box')?.clientWidth ?? 0;
  const noCredit = maxSubscriptions <= subscribedCount;
  const earningsUpdateNotificationByCompanyId = useSelector(
    modelsSelectors.earningsUpdateNotifications
  );
  const { allowedSectors } = useSelectors.User();
  const sectorsList = useSelector(modelsSelectors.getSectorsList);
  const dispatch = useDispatch();
  const styles = useStyles();
  const itemWidth = useMemo(() => calculateItemWidth(containerWidth, numberOfColumns), [
    containerWidth
  ]);
  const isAllowed = useMemo(() => {
    if (!isEmpty(allowedSectors)) {
      const modelSector = sectorsList?.find((s) =>
        s?.industriesSet.find((i) => i.id === industry?.id)
      );
      if (modelSector) {
        return allowedSectors?.includes(modelSector.id);
      }
    }
    return false;
  }, [sectorsList, allowedSectors]);

  return (
    <div className={styles.mainContainer} ref={containerRef}>
      {clientModelsNotDisabled?.length ? (
        <Box border={1} mb={2} borderColor="border.main" data-testid="industry-box" width={'100%'}>
          {clientModelsNotDisabled?.length && (
            <Box
              fontWeight="fontWeightBold"
              fontSize={16}
              p={1}
              m={0}
              pl={3}
              bgcolor="headingBG.main"
              data-testid="industry-title"
            >
              {industry?.name} (
              <span data-testid="model-count-in-industry">{numberOfModelsInIndustry}</span>)
            </Box>
          )}
          <div className={styles.container} ref={containerRef}>
            {showList && clientModelsNotDisabled ? (
              <ModelsTable
                onSubscribeModel={onSubscribeModel}
                onUnSubscribeModel={onUnSubscribeModel}
                clientModels={clientModelsNotDisabled}
                subscriptionPendingModel={subscriptionPendingModel}
                showNotAllowed={isFreeTier && !isAllowed}
                noCredit={isFreeTier && noCredit && isAllowed}
                isTrialTier={isTrialTier}
              />
            ) : (
              clientModelsNotDisabled?.length && (
                <WindowScroller>
                  {({ height, isScrolling, onChildScroll, scrollTop, registerChild }) => (
                    <AutoSizer disableHeight key={`auto-sizer-${itemWidth}-${industry?.id}`}>
                      {() => {
                        const rowCount = Math.ceil(
                          clientModelsNotDisabled.length / numberOfColumns
                        );
                        return (
                          <div ref={(el) => registerChild(el)}>
                            <Grid
                              autoHeight={true}
                              columnCount={numberOfColumns}
                              columnWidth={itemWidth}
                              width={containerWidth}
                              height={height}
                              rowCount={rowCount}
                              rowHeight={150}
                              isScrolling={isScrolling}
                              scrollTop={scrollTop}
                              onScroll={onChildScroll}
                              cellRenderer={(cellProps: GridCellProps) => {
                                const fullProps = {
                                  ...props,
                                  ...cellProps,
                                  clientModels: clientModelsNotDisabled,
                                  numberOfColumns,
                                  earningsUpdateNotificationByCompanyId,
                                  showNotAllowed: Boolean(isFreeTier && !isAllowed),
                                  noCredit: Boolean(isFreeTier && noCredit && isAllowed),
                                  handleDownload: ({ company, id }: clientModels) => () => {
                                    dispatch(
                                      setDownloadModelData({
                                        companyTicker: company.ticker,
                                        modelId: id
                                      })
                                    );
                                    dispatch(
                                      toggleDialog({
                                        action: 'open',
                                        id: DIALOG_IDS.DOWNLOAD_MODEL
                                      })
                                    );
                                  }
                                };
                                return renderItem(fullProps);
                              }}
                            />
                          </div>
                        );
                      }}
                    </AutoSizer>
                  )}
                </WindowScroller>
              )
            )}
            {numberOfModelsInIndustry > numberOfIndustriesByDefault &&
              clientModels?.length != numberOfModelsInIndustry &&
              loadMore && (
                <Box
                  textAlign="center"
                  className={styles.loadMore}
                  style={{ left: containerWidth / 2 }}
                >
                  <Button
                    disabled={Boolean(
                      isIndustryModelLoading && isIndustryModelLoading === parseInt(industry?.id)
                    )}
                    data-testid="load-more-btn"
                    color="primary"
                    onClick={() => {
                      loadMore(industry?.id);
                      Mixpanel.track('marketplace:load_more', industry);
                    }}
                  >
                    {loadMoreLabel}
                    {isIndustryModelLoading &&
                      isIndustryModelLoading === parseInt(industry?.id) && (
                        <CircularProgress size={24} />
                      )}
                  </Button>
                </Box>
              )}
          </div>
        </Box>
      ) : (
        ' '
      )}
    </div>
  );
};

export default React.memo(ModelList);
