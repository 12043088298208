import { gql } from '@apollo/client';
import { Series } from 'domain/models';

export const SERIES_FUNDAMENTAL_SERVICE = gql`
  query modelLivePreviewSeriesQuery($companyId: String!, $periods: [String]!, $seriesIds: [Int]!) {
    modelLivePreviewSeries(companyId: $companyId, periods: $periods, seriesIds: $seriesIds) {
      series {
        __typename
        id
        name
        rowNumber
        fundamentals {
          __typename
          id
          value
          normalizedValue
          period
          context
          status
          publishedAt
          publishedIn
          createdAt
          document {
            __typename
            filing {
              acceptedDate
            }
            filingType
            url
            createdAt
          }
          type
        }
        document {
          __typename
          filingType
          id
          url
          createdAt
        }
      }
      periods
      updatedPeriods
    }
  }
`;

export namespace SeriesFundamentalService {
  export type Params = {
    companyId: string;
    periods: string[];
    seriesIds: number[];
  };

  export type Response = {
    modelLivePreviewSeries: {
      series: Series[];
      periods: string[];
      updatedPeriods: string[];
    };
  };
}
