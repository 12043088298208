import { styled } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'space-between',
  padding: '1rem',
  background: '#E8F3FF',
  borderRadius: '0.5rem',
  marginBottom: '1rem',
  alignItems: 'flex-end',

  '& > a': {
    marginTop: '0.5rem',

    '& > button': {
      color: '#000000DE',
      borderColor: '#000000DE'
    }
  }
});

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: 'fit-content'
});

export const Title = styled('div')({
  fontSize: '1rem',
  fontWeight: 700,
  color: '#000000'
});
