export const getTimeFormatted = (seconds: number | undefined) => {
  if (seconds === undefined) {
    return '0 min';
  }

  const hrs = Math.floor(seconds / 3600);
  const mins = Math.floor((seconds % 3600) / 60);

  let formattedTime = '';
  if (hrs > 0) {
    formattedTime += `${hrs} hr `;
  }
  if (mins > 0) {
    formattedTime += `${mins} min`;
  }

  return formattedTime.trim();
};

export const formatDateTime = (timeStr: string) => {
  const date = new Date(timeStr);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    timeZoneName: 'short'
  };
  return date.toLocaleDateString(undefined, options);
};
