import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { InnerSideBarContainer, PageInfo } from './SourcePage.styles';
import { useSearchParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Tooltip } from 'presentation/components';
import { useParams, useSourceServices } from 'presentation/hooks';
import { FundamentalsTable } from './FundamentalsTable';

export type SideBarRef = {
  collpased: boolean;
  loading: boolean;
  collapse: () => void;
  expand: () => void;
  toggle: () => void;
};

type Props = {
  name: string;
  ticker: string;
};

export const CompositeSideBar = forwardRef<SideBarRef, Props>(({ name, ticker }, ref) => {
  const { id = '' } = useParams<'SOURCE'>();
  const [search] = useSearchParams();

  const selectedFundamental = search.get('fundamentalId') ?? '';

  const { data, loading } = useSourceServices().compositeFundamentalInfo({
    variables: {
      fundamentalId: id
    },
    skip: !id
  });

  const [collpased, setCollapsed] = useState(false);

  useImperativeHandle(ref, () => ({
    collpased,
    loading: false,
    collapse: () => {
      setCollapsed(true);
    },
    expand: () => {
      setCollapsed(false);
    },
    toggle: () => {
      setCollapsed((prev) => !prev);
    }
  }));

  if (loading)
    return (
      <InnerSideBarContainer>
        <CircularProgress size={16} style={{ margin: ' 32px auto' }} />
      </InnerSideBarContainer>
    );

  if (!data?.compositeFundamentals) return <></>;

  const { operations, result } = data.compositeFundamentals;

  const fundamentals = operations.map((operation) => ({
    ...operation,
    label: operation.period
  }));

  return (
    <InnerSideBarContainer collapsed={collpased} fundamentalSelected={!!selectedFundamental}>
      <div>
        <PageInfo>
          Cumulative number
          <Tooltip title={`${ticker} - ${name}`}>
            <span>
              {ticker} - {name}
            </span>
          </Tooltip>
        </PageInfo>
        <FundamentalsTable
          columnLabel="Period"
          columnValue="Value"
          fundamentals={fundamentals}
          result={{ ...result, label: result.period }}
        />
      </div>
    </InnerSideBarContainer>
  );
});
