import { HttpClient, HttpRequest, HttpResponse, HttpError, CSRFTOKEN } from 'application/protocols';
import axios, { AxiosError, AxiosInstance } from 'axios';
import { Cookies } from 'infra/storage';
import { getBaseUrl } from '../utils';

export class AxiosHttpClientAdapter implements HttpClient {
  private readonly axiosInstance: AxiosInstance;
  constructor() {
    const baseURL = getBaseUrl();

    this.axiosInstance = axios.create({
      baseURL
    });
  }

  async request<R = unknown, T = unknown>({
    method,
    url,
    body,
    headers,
    queryParams,
    withCredentials
  }: HttpRequest<T>): Promise<HttpResponse<R>> {
    try {
      const customHeaders = { ...headers };

      const csrfToken = Cookies.get(CSRFTOKEN);

      if (csrfToken) {
        customHeaders['X-CSRFToken'] = csrfToken;
      }

      const axiosResponse = await this.axiosInstance.request({
        withCredentials,
        url,
        data: body,
        headers: customHeaders,
        method,
        params: queryParams
      });
      return {
        statusCode: axiosResponse.status,
        data: axiosResponse.data
      };
    } catch (error) {
      const axiosError = error as AxiosError;
      console.error('[Marketplace] REST request error: ', axiosError);
      return {
        error: axiosError.response?.data,
        statusCode: axiosError.response?.status,
        message: axiosError.response?.statusText
      } as HttpError;
    }
  }
}
