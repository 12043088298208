import React, { useMemo, useRef } from 'react';
import { usePageTitle, useParams, useSourceServices } from 'presentation/hooks';
import { RolledUpSideBar, SideBarRef } from './RolledUpSideBar';
import { SourceContent } from './SourceContent';
import { Container, DocumentPlaceholder } from './SourcePage.styles';
import { useSearchParams } from 'react-router-dom';
import { FundamentalType } from 'domain/models';

export const SourcePage = () => {
  const { id } = useParams<'SOURCE'>();
  const [search] = useSearchParams();

  // Rolled-up number parameters
  const companyId = search.get('company_id') ?? '';
  const period = search.get('period') ?? '';
  const formula = search.get('formula');

  // Stitcher parameters
  const fundamentalIds = search.getAll('id');
  const values = search.getAll('value');

  // Composite number parameters
  const selectedFundamental = search.get('fundamentalId') ?? '';

  const innerSideBarRef = useRef<SideBarRef>(null);

  const fundamentalId = useMemo(() => {
    const numericId = Number(
      id ?? fundamentalIds.at(-1) ?? search.get('fundamentalId') ?? undefined // Fallback to undefined as search returns null
    );
    if (!isNaN(numericId)) return String(numericId);
    return '';
  }, [id, fundamentalIds, search]);

  const isRolledUp = Boolean(companyId && period && formula);
  const isStitcher = !!fundamentalIds.length && !!values.length;

  const { data, loading, error } = useSourceServices().fundamentalInfo({
    variables: {
      fundamentalId
    },
    skip: !fundamentalId
  });

  const isComposite = data?.fundamentalInfo?.type === FundamentalType.COMPOSITE;

  const pageTitle = useMemo(() => {
    if (!data?.fundamentalInfo) return '';

    const title = `${data?.fundamentalInfo?.company?.ticker} - ${data?.fundamentalInfo?.fullSeriesName}`;

    if (isComposite) {
      return `${title} - Composite`;
    }
    return title;
  }, [data, isComposite]);

  usePageTitle(pageTitle);

  const showPlaceholder =
    (!fundamentalId && !innerSideBarRef.current?.loading) || (isComposite && !selectedFundamental);

  if (error || (!loading && !isRolledUp && !data?.fundamentalInfo)) {
    throw new Error(
      `Failed to load fundamental info -> ID: ${fundamentalId}, message: ${error?.message}`
    );
  }

  return (
    <Container>
      {isRolledUp && <RolledUpSideBar ref={innerSideBarRef} selectedFundamental={fundamentalId} />}
      {!!fundamentalId && (
        <SourceContent
          data={data}
          fundamentalId={fundamentalId}
          innerSideBarRef={innerSideBarRef}
          isStitcher={isStitcher}
          loading={loading}
          selectedFundamental={selectedFundamental}
        />
      )}
      {showPlaceholder && (
        <DocumentPlaceholder>Select a data point to view its details</DocumentPlaceholder>
      )}
    </Container>
  );
};
