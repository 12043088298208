import mixpanel, { Dict } from 'mixpanel-browser';

export const Mixpanel = (() => {
  const tryCatch = <T>(fn: () => T): T | undefined => {
    try {
      return fn();
    } catch (error) {
      console.error('[Marketplace] Mixpanel Error ', error);
      return undefined;
    }
  };

  const init = () =>
    tryCatch(() => {
      mixpanel.init('46c30ade1d77fa9bce319f2093f7f48a', {
        debug: process.env.REACT_APP_ENV !== 'production',
        autotrack: true
      });
      mixpanel.register({
        env: process.env.REACT_APP_ENV
      });
    });

  const reset = () => {
    tryCatch(() => mixpanel.reset());
  };
  const identify = (id: string) => {
    tryCatch(() => mixpanel.identify(id));
  };
  const alias = (id: string) => {
    tryCatch(() => mixpanel.alias(id));
  };
  const track = (name: string, props?: Dict) => {
    tryCatch(() => mixpanel.track(name, props));
  };
  const people = {
    set: (props: { [key: string]: string | number | unknown }) => {
      tryCatch(() => mixpanel.people.set(props));
    }
  };
  const getDistinctId = () => tryCatch(() => mixpanel.get_distinct_id());

  return {
    alias,
    getDistinctId,
    identify,
    init,
    people,
    reset,
    track
  };
})();
