import { gql } from '@apollo/client';

export const COMPANY_MODEL_PREVIEW_SERVICE = gql`
  query modelLivePreviewQuery(
    $companyId: String!
    $filingTypes: [String]
    $publishedOnly: Boolean
    $query: String
  ) {
    modelLivePreviewSections(
      companyId: $companyId
      filingTypes: $filingTypes
      publishedOnly: $publishedOnly
      query: $query
    ) {
      sections {
        name
        seriesIds
      }
      periods
    }
  }
`;

export namespace CompanyModelPreviewService {
  export type Params = {
    companyId: string;
    query?: string;
    filingTypes?: string[];
    publishedOnly?: boolean;
  };

  export type Response = {
    modelLivePreviewSections: {
      sections: {
        name: string;
        seriesIds: number[];
      }[];
      periods: string[];
    };
  };
}
