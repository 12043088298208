import React from 'react';
import { Section, DataRow } from './TableSection.styles';
import { useCompanyServices, useParams } from 'presentation/hooks';
import { CircularProgress } from '@mui/material';
import { Fundamental } from './Fundamental';
import { RightChevronFilledIcon, Tooltip } from 'presentation/components';
import { UpdatesPageError } from '../UpdatesPageError/UpdatesPageError';
import { generatePath } from 'react-router';
import { routes } from 'main/router';
import { Link } from 'react-router-dom';

type Props = {
  filingTypeVisible: boolean;
  name: string;
  open: boolean;
  periods: string[];
  periodsList: string[];
  seriesIds: number[];
  onSectionClick: () => void;
};

export const TableSection = ({
  filingTypeVisible,
  name,
  open,
  periods,
  periodsList,
  seriesIds,
  onSectionClick
}: Props) => {
  const { companyId } = useParams<'LIVE_UPDATES'>();

  const { data, loading, error } = useCompanyServices().seriesFundamental({
    variables: { companyId, periods, seriesIds },
    skip: !open
  });

  const series = data?.modelLivePreviewSeries?.series ?? [];

  return (
    <>
      <Section open={open} onClick={onSectionClick}>
        <td>
          <RightChevronFilledIcon /> {name}
        </td>
      </Section>

      {open && (
        <>
          {loading && (
            <DataRow>
              <td className="full-width">
                <CircularProgress size={12} />
              </td>
            </DataRow>
          )}
          {error && (
            <tr>
              <td className="full-width">
                <UpdatesPageError />
              </td>
            </tr>
          )}
          {!loading &&
            !error &&
            series.map(({ id, name, document, fundamentals }) => (
              <DataRow key={id}>
                <Tooltip title={name}>
                  <td>{name}</td>
                </Tooltip>
                {filingTypeVisible && (
                  <td>
                    {document?.filingType && (
                      <Link to={generatePath(routes.DOCUMENT, { id: document.id })}>
                        {document?.filingType}
                      </Link>
                    )}
                    {!document?.filingType && '-'}
                  </td>
                )}
                {periodsList.map((period) => {
                  const fundamental = fundamentals.find((f) => f.period === period);

                  return <Fundamental key={`${id}-${period}`} fundamental={fundamental} />;
                })}
              </DataRow>
            ))}
        </>
      )}
    </>
  );
};
