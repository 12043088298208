import React, { useEffect } from 'react';
import { DefaultLayout } from 'main/layout';
import { useToastContext } from 'presentation/contexts';

const ErrorBoundaryInner = () => {
  const toast = useToastContext();

  useEffect(() => {
    toast.error('An error occurred. Please try again later.');
  }, [toast]);
  return <h2 style={{ margin: '100px auto' }}>Something went wrong. Please try again later.</h2>;
};

export const ErrorBoundary = () => {
  return (
    <DefaultLayout>
      <ErrorBoundaryInner />
    </DefaultLayout>
  );
};
