import React from 'react';
import { styled } from '@mui/material';
import { generatePath } from 'react-router';
import { routes } from 'main/router';
import { Mixpanel } from 'infra/mixpanel';
import { Link, useSearchParams } from 'react-router-dom';

const TableWrapper = styled('div')({
  width: '100%',

  '& > table': {
    background: '#FFFFFF',
    width: '100%',
    tableLayout: 'fixed',
    borderCollapse: 'collapse',
    fontSize: '0.75rem',

    '& thead': {
      '& tr th': {
        fontWeight: 400
      }
    },

    '& tr': {
      border: '1px solid #0000001F',
      borderLeft: 0,
      borderRight: 0,

      '& th, & td': {
        textAlign: 'right',
        padding: '0.5rem 1rem',

        '&:first-of-type': {
          textAlign: 'left',
          wordBreak: 'break-word'
        },
        '&:last-of-type': {
          paddingLeft: 0
        }
      },

      '& td': {
        '&.value': {
          minWidth: 'fit-content',
          whiteSpace: 'nowrap'
        },

        '& a': {
          color: '#000000DE'
        }
      }
    },

    '& .operator': {
      width: '8px',
      paddingLeft: 0,
      paddingRight: 0,

      '& span': {
        color: '#D32F2F',
        fontWeight: 700
      }
    }
  }
});

const TableRow = styled('tr')<{ selected?: boolean }>(({ selected }) => ({
  background: selected ? '#2196F314' : '#FFFFFF',
  outline: selected ? '1px solid #2196F380' : 'none',

  '& td, & a': {
    fontWeight: selected ? 700 : 400
  }
}));

type Props = {
  columnLabel: string;
  columnValue: string;
  fundamentals: Array<{
    id: string;
    label: string;
    operator?: string;
    value?: string;
  }>;
  result: {
    label?: string;
    value?: string;
  };
};

export const FundamentalsTable = ({ columnLabel, columnValue, fundamentals, result }: Props) => {
  const [search, setSearch] = useSearchParams();

  const selectedFundamental = search.get('fundamentalId') ?? '';

  const handleFundamentalClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    const id = e.currentTarget.id;
    setSearch((prev) => {
      prev.set('fundamentalId', id);
      return prev;
    });
    Mixpanel.track('marketplace:src:fundamental-click', { id });

    if (e.button === 0) {
      e.preventDefault();
    }
  };
  return (
    <TableWrapper>
      <table>
        <colgroup>
          <col style={{ width: 'auto' }} />
          <col style={{ width: '8px' }} />
          <col style={{ width: '80px' }} />
        </colgroup>
        <thead>
          <tr>
            <th>{columnLabel}</th>
            <th className="operator" />
            <th>{columnValue}</th>
          </tr>
        </thead>
        <tbody>
          {fundamentals.map(({ label, id, value, operator }, index) => (
            <TableRow key={index} selected={id === selectedFundamental}>
              <td>{label}</td>
              <td className="operator">{!!operator && !!index && <span>{operator}</span>}</td>
              <td className="value">
                {!value && 'Not Released'}
                {!!value && (
                  <Link
                    id={id}
                    to={generatePath(routes.SOURCE, { id })}
                    onClick={handleFundamentalClick}
                  >
                    {value}
                  </Link>
                )}
              </td>
            </TableRow>
          ))}
          <tr>
            <td>{result.label}</td>
            <td className="operator">
              <span>=</span>
            </td>
            <td>{result.value}</td>
          </tr>
        </tbody>
      </table>
    </TableWrapper>
  );
};
