import React from 'react';

export const FilterIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.18702 4.2075C4.70202 6.15 7.49952 9.75 7.49952 9.75V14.25C7.49952 14.6625 7.83702 15 8.24952 15H9.74952C10.162 15 10.4995 14.6625 10.4995 14.25V9.75C10.4995 9.75 13.2895 6.15 14.8045 4.2075C15.187 3.7125 14.8345 3 14.212 3H3.77952C3.15702 3 2.80452 3.7125 3.18702 4.2075Z"
      fill="#404245"
    />
  </svg>
);
