import { createStore, applyMiddleware, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { fork } from 'redux-saga/effects';
import { composeWithDevTools } from 'redux-devtools-extension';
import localizationReducer from 'localization/reducer';
import ModelsReducer from 'pages/Models/duck';
import { ModelsSaga } from 'pages/Models/duck';
import { all } from '@redux-saga/core/effects';
import { authReducer, generalReducer, userReducer } from './slices';

const rootReducer = combineReducers({
  auth: authReducer,
  general: generalReducer,
  user: userReducer,
  localizationReducer,
  ModelsReducer
});
function* rootSaga() {
  yield all([fork(ModelsSaga)]);
}

const sagaMiddleware = createSagaMiddleware();

export const makeStore = () => {
  const store = createStore(rootReducer, composeWithDevTools(applyMiddleware(sagaMiddleware)));

  sagaMiddleware.run(rootSaga);
  return store;
};

export * from './slices/actions';

export const store = makeStore();
