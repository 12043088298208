import React, { useCallback, useEffect, useState } from 'react';
import { Mixpanel } from 'infra/mixpanel';
import { SearchIcon, Tooltip } from 'presentation/components';
import { useDebounce } from 'presentation/hooks';
import { useSearchParams } from 'react-router-dom';
import { Container, Input } from './SearchInput.styles';

type Props = {
  className?: string;
  placeholder?: string;
  searchEventName: string;
};

export const SearchInput = ({ className, placeholder = 'Search text', searchEventName }: Props) => {
  const [params, setParams] = useSearchParams();

  const [query, setQuery] = useState(decodeURIComponent(params.get('q') ?? ''));
  const debouncedQuery = useDebounce(query);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value.trimStart());
  };

  const handleSearch = useCallback(() => {
    setParams((p) => {
      if (!debouncedQuery.trim()) {
        p.delete('q');
      } else {
        p.set('q', encodeURIComponent(debouncedQuery.trim()));
      }
      return p;
    });
  }, [debouncedQuery, setParams]);

  const handleKeyUp = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  useEffect(() => {
    if (debouncedQuery.length) {
      Mixpanel.track(searchEventName, { query: debouncedQuery });
    }
    handleSearch();
  }, [debouncedQuery, handleSearch, searchEventName]);

  return (
    <Container className={className} onKeyUp={handleKeyUp}>
      <Input
        type="text"
        placeholder={placeholder}
        value={query}
        onChange={handleChange}
        maxLength={100}
      />
      <Tooltip title="Search">
        <SearchIcon onClick={handleSearch} />
      </Tooltip>
    </Container>
  );
};
