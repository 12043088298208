import React, { memo } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import Models from 'pages/Models';

import { routes } from './routes';
import { NavigateWithParams } from './NavigateWithParams';

export const RouterV1 = memo(() => {
  return (
    <Routes>
      <Route
        path={routes.LIVE_PREVIEW}
        element={<NavigateWithParams to={routes.LIVE_UPDATES} replace />}
      />
      <Route path={routes.MODELS} element={<Models />} />
      <Route path={routes.HOME} element={<Models />} />
      <Route path="*" element={<Navigate to={`${routes.HOME}?v1=true`} replace />} />
    </Routes>
  );
});
