import { QueryHookOptions, useMutation, useQuery } from '@apollo/client';
import {
  DOCUMENT_INFO_SERVICE,
  DocumentInfoService,
  FUNDAMENTAL_INFO_SERVICE,
  FundamentalInfoService,
  REPORT_FUNDAMENTAL_ERROR_SERVICE,
  ReportFundamentalErrorService
} from 'application/services';
import {
  COMPOSITE_FUNDAMENTAL_INFO_SERVICE,
  CompositeFundamentalInfoService
} from 'application/services/source/composite-fundamental-info-service';
import { Mixpanel } from 'infra/mixpanel';
import { useToastContext } from 'presentation/contexts';

export const useSourceServices = () => {
  const toast = useToastContext();

  const errorMessage = `Something went wrong. Please try again later, or contact us at ${process.env.REACT_APP_DALOOPA_SUPPORT_EMAIL}`;

  const onError = (eventName: string, variables?: Record<string, string>) => () => {
    Mixpanel.track(eventName, { variables });

    toast.error(errorMessage);
  };

  const useCompositeFundamentalInfo = (
    options?: QueryHookOptions<
      CompositeFundamentalInfoService.Response,
      CompositeFundamentalInfoService.Params
    >
  ) =>
    useQuery<CompositeFundamentalInfoService.Response, CompositeFundamentalInfoService.Params>(
      COMPOSITE_FUNDAMENTAL_INFO_SERVICE,
      {
        fetchPolicy: 'no-cache',
        onCompleted: ({ compositeFundamentals }) => {
          if (!compositeFundamentals.result) {
            toast.error(errorMessage);

            Mixpanel.track('marketplace:src:composite_fundamental:fetch_failed', {
              variables: options?.variables
            });
          }
        },
        onError: onError('marketplace:src:composite_fundamental:fetch_failed', options?.variables),
        ...options
      }
    );

  const useDocumentInfo = (
    options?: QueryHookOptions<DocumentInfoService.Response, DocumentInfoService.Params>
  ) =>
    useQuery<DocumentInfoService.Response, DocumentInfoService.Params>(DOCUMENT_INFO_SERVICE, {
      fetchPolicy: 'no-cache',
      onCompleted: ({ documentInfo }) => {
        if (!documentInfo.id) {
          toast.error(errorMessage);

          Mixpanel.track('marketplace:document:fetch_failed', {
            variables: options?.variables
          });
        }
      },
      onError: onError('marketplace:document:fetch_failed', options?.variables),
      ...options
    });

  const useFundamentalInfo = (
    options?: QueryHookOptions<FundamentalInfoService.Response, FundamentalInfoService.Params>
  ) =>
    useQuery<FundamentalInfoService.Response, FundamentalInfoService.Params>(
      FUNDAMENTAL_INFO_SERVICE,
      {
        fetchPolicy: 'no-cache',
        onCompleted: ({ fundamentalInfo }) => {
          if (!fundamentalInfo.id) {
            toast.error(errorMessage);

            Mixpanel.track('marketplace:src:fundamental:fetch_failed', {
              variables: options?.variables
            });
          }
        },
        onError: onError('marketplace:src:fundamental:fetch_failed', options?.variables),
        ...options
      }
    );

  const useErrorReport = () =>
    useMutation<ReportFundamentalErrorService.Response, ReportFundamentalErrorService.Params>(
      REPORT_FUNDAMENTAL_ERROR_SERVICE,
      {
        onCompleted: (_, opt) => {
          Mixpanel.track('marketplace:src:error_report:submit', { variables: opt?.variables });
          toast.success('Report submitted successfully');
        },
        onError: (_, opt) => {
          Mixpanel.track('marketplace:src:error_report:submit', { variables: opt?.variables });

          toast.error(errorMessage);
        }
      }
    );

  return {
    compositeFundamentalInfo: useCompositeFundamentalInfo,
    documentInfo: useDocumentInfo,
    fundamentalInfo: useFundamentalInfo,
    errorReport: useErrorReport
  };
};
