import { gql } from '@apollo/client';

export const REPORT_FUNDAMENTAL_ERROR_SERVICE = gql`
  mutation reportFundamentalError($fundamentalId: Int, $errorType: String, $description: String) {
    reportFundamentalError(
      fundamentalId: $fundamentalId
      errorType: $errorType
      description: $description
    ) {
      success
      error
      id
    }
  }
`;

export namespace ReportFundamentalErrorService {
  export type Params = {
    fundamentalId: number;
    errorType: string;
    description: string;
  };

  export type Response = {
    reportFundamentalError: {
      success: boolean;
      error: string;
      id: number;
    };
  };
}
