import { Button, styled } from '@mui/material';

export const ActionButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== 'bgLight' && prop !== 'error'
})<{
  bgLight?: boolean;
  error?: boolean;
}>(({ bgLight, variant, error }) => ({
  gap: '8px',
  minWidth: 'fit-content',
  width: '115px',
  height: '30px',
  borderRadius: '4px',
  color: '#000000DE',
  fontSize: '12px',
  fontWeight: 'bold',
  lineHeight: 1,
  whiteSpace: 'nowrap',

  ...(variant !== 'text' && {
    border: '1px solid #E8E8E8',
    background: bgLight ? '#FFFFFF' : '#E8E8E8',

    '&:hover': {
      background: bgLight ? '#F2F2F2' : '#F8F8F8'
    }
  }),

  ...(error && {
    color: '#D32F2F',

    '&:hover': {
      background: '#D32F2F0A'
    }
  }),

  '& svg': {
    minWidth: '18px',
    minHeight: '18px'
  }
}));
