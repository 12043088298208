import React from 'react';
import { RouteObject } from 'react-router';
import { routes } from '../routes';
import { NavigateWithParams } from '../NavigateWithParams';

export const redirectedRoutes: RouteObject[] = [
  {
    path: routes.LIVE_PREVIEW,
    element: <NavigateWithParams to={routes.LIVE_UPDATES} replace />
  },
  {
    path: routes.ROLLUP_NUMBERS,
    element: <NavigateWithParams to={routes.SOURCE} replace />
  },
  {
    path: routes.TEXT_FUNDAMENTAL,
    element: <NavigateWithParams to={routes.SOURCE} replace />
  }
];
