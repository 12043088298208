import React, { useCallback, useEffect, useState } from 'react';

import { DIALOG_IDS, ModelType } from 'domain/models';
import { Button } from 'presentation/components';
import { useGeneralServices, useSelectors } from 'presentation/hooks';
import { useDispatch } from 'react-redux';
import {
  Container,
  Details,
  StyledDialog,
  StyledModelTypeSelector
} from './DownloadModelDialog.styles';
import { setDownloadModelData, toggleDialog } from 'store';
import { useToastContext } from 'presentation/contexts';
import { Mixpanel } from 'infra/mixpanel';
import { validatedModelDownload } from 'presentation/utils';

export const DownloadModelDialog = () => {
  const dispatch = useDispatch();
  const toast = useToastContext();

  const [downloadModel, { loading }] = useGeneralServices().modelDownload();

  const { isAuthenticated } = useSelectors.Auth();
  const { downloadModelData, activeDialogIDs } = useSelectors.General();
  const { downloadFormat } = useSelectors.User();

  const [modelType, setModelType] = useState<ModelType>();

  const onDownload = useCallback(
    (modelType: ModelType) => {
      if (!downloadModelData?.modelId) return;
      const { companyTicker, modelId } = downloadModelData;
      downloadModel({ variables: { modelId, modelType } })
        .then(({ data }) => {
          const url = data?.modelDownload?.url ?? '';

          const requestFailed =
            !url || !data?.modelDownload.success || !!data?.modelDownload?.errors?.length;

          if (requestFailed) {
            const error = data?.modelDownload?.errors?.[0] ?? 'Failed to generate download url';
            throw new Error(error);
          }

          return validatedModelDownload(url, modelId, modelType, companyTicker).then(() => {
            dispatch(setDownloadModelData(undefined));
            dispatch(toggleDialog({ action: 'close', id: DIALOG_IDS.DOWNLOAD_MODEL }));
          });
        })
        .catch((e) => {
          console.error('[Marketplace] Download Model', e);
          toast.error(e?.message ?? 'Failed to download model. Please try again.');
        });
    },
    [dispatch, downloadModel, downloadModelData, toast]
  );

  const handleDownload = useCallback(() => {
    if (!modelType) return;

    onDownload(modelType);
  }, [modelType, onDownload]);

  const handleClose = useCallback(() => {
    dispatch(setDownloadModelData(undefined));
  }, [dispatch]);

  useEffect(() => {
    if (activeDialogIDs.includes(DIALOG_IDS.DOWNLOAD_MODEL) && !downloadFormat) {
      Mixpanel.track('marketplace:download:dialog-seen');
    }
    if (
      activeDialogIDs.includes(DIALOG_IDS.DOWNLOAD_MODEL) &&
      !!downloadFormat &&
      !!downloadModelData?.modelId
    ) {
      onDownload(downloadFormat);
    }
  }, [activeDialogIDs, downloadFormat, downloadModelData, onDownload]);

  if (!isAuthenticated || !downloadModelData?.modelId || downloadFormat) {
    return <></>;
  }

  return (
    <StyledDialog
      id={DIALOG_IDS.DOWNLOAD_MODEL}
      onClose={handleClose}
      header="Data Sheet Download Setting"
    >
      <Container>
        <Details>
          Choose how you’d like to format your download. These preferences will be saved for future
          downloads, but you can update them anytime in Settings.
        </Details>
        <StyledModelTypeSelector value={modelType} onChange={setModelType} />
        <Button
          variant="contained"
          size="large"
          onClick={handleDownload}
          disabled={!modelType || loading}
        >
          Set Preference & Download
        </Button>
      </Container>
    </StyledDialog>
  );
};
