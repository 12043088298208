import { gql } from '@apollo/client';
import { MODEL_STATUS } from 'domain/models';

export const COMPANY_DETAILS_SERVICE = gql`
  query company($companyId: String) {
    company(companyId: $companyId) {
      id
      ticker
      name
      favicon
      disabled
      modelId
      status
    }
  }
`;

export namespace CompanyDetailsService {
  export type Params = {
    companyId: string;
  };

  export type Response = {
    company: {
      disabled: boolean;
      favicon: string;
      id: string;
      isSubscribed: boolean;
      modelId: number;
      name: string;
      ticker: string;
      status: MODEL_STATUS;
    };
  };
}
