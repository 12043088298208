import React from 'react';
import { routes } from '../routes';
import { RouteObject } from 'react-router';
import { ProtectedRoute } from '../ProtectedRoute';
import { IndustryPage, SettingsPage, LiveUpdatesPage } from 'presentation/pages';
import { SourcePage } from 'presentation/pages/source/SourcePage';
import { DocumentViewPage } from 'presentation/pages/document/DocumentViewPage';

export const protectedRoutes: RouteObject[] = [
  {
    path: routes.INDUSTRY,
    element: (
      <ProtectedRoute>
        <IndustryPage />
      </ProtectedRoute>
    )
  },
  {
    path: routes.SETTINGS,
    element: (
      <ProtectedRoute>
        <SettingsPage />
      </ProtectedRoute>
    )
  },
  {
    path: routes.LIVE_UPDATES,
    element: (
      <ProtectedRoute>
        <LiveUpdatesPage />
      </ProtectedRoute>
    )
  },
  {
    path: routes.SOURCE,
    element: (
      <ProtectedRoute ipWhitelisted>
        <SourcePage />
      </ProtectedRoute>
    )
  },
  {
    path: routes.DOCUMENT,
    element: (
      <ProtectedRoute ipWhitelisted>
        <DocumentViewPage />
      </ProtectedRoute>
    )
  }
];
