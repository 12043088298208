import React from 'react';
import { DefaultLayout, OldLayout } from 'main/layout';
import { ErrorBoundary } from 'presentation/components';
import { RouteObject } from 'react-router';
import { authRoutes } from './auth-routes';
import { errorRoutes } from './error-routes';
import { openRoutes } from './open-routes';
import { protectedRoutes } from './protected-routes';
import { redirectedRoutes } from './redirected-routes';

export const appRoutes: RouteObject[] = [
  {
    path: '/',
    element: <DefaultLayout />,
    ErrorBoundary,
    children: [
      ...errorRoutes,
      ...authRoutes,
      ...protectedRoutes,
      ...openRoutes,
      ...redirectedRoutes
    ]
  }
];

export const appRoutesV1: RouteObject[] = [
  {
    path: '*',
    element: <OldLayout />,
    ErrorBoundary
  }
];
