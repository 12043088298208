import { gql } from '@apollo/client';

export const MODEL_DOWNLOAD_SERVICE = gql`
  mutation modelDownload($modelId: String!, $modelType: String!) {
    modelDownload(modelId: $modelId, modelType: $modelType) {
      errors
      success
      url
    }
  }
`;

export namespace ModelDownloadService {
  export type Params = {
    modelId: string | number;
    modelType: string;
  };

  export type Response = {
    modelDownload: {
      errors?: string[];
      success: boolean;
      url: string;
    };
  };
}
