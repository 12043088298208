import React, { useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { useStatsigClient } from '@statsig/react-bindings';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'core';
import { themeV2 } from 'main/config/theme';
import { Mixpanel } from 'infra/mixpanel';
import { ApplicationDialogs } from 'presentation/components';
import { ToastContextProvider } from 'presentation/contexts';
import { useSelectors } from 'presentation/hooks';
import { useAppSelector } from 'store/hooks';
import { generalState } from 'store/slices';
import { DaloopaTheme } from 'ui-kit';

const ContentWrapper = ({ children }: { children: React.ReactNode }) => {
  const { client } = useStatsigClient();

  const userData = useSelectors.User();

  const enableGuidedTour = client.checkGate('enable-guided-tour-commandai');

  useEffect(() => {
    if (enableGuidedTour && userData.id) {
      void window.CommandBar.boot(userData.email, userData);
      void window.CommandBar.addEventSubscriber((eventName, eventData) => {
        Mixpanel.track('commandai:marketplace:'.concat(eventName), eventData);
      });
    } else {
      window.CommandBar.shutdown();
    }
    return () => {
      window.CommandBar.shutdown();
    };
  }, [enableGuidedTour, userData]);

  return <>{children}</>;
};

type Props = {
  children: React.ReactNode;
};

export const ProviderComposition = ({ children }: Props) => {
  const {
    featureFlags: { isMarketplaceV2Enabled }
  } = useAppSelector(generalState);

  const theme = isMarketplaceV2Enabled ? themeV2 : DaloopaTheme;

  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <ToastContextProvider>
          <CssBaseline />
          <ContentWrapper>{children}</ContentWrapper>
          <ApplicationDialogs />
        </ToastContextProvider>
      </QueryClientProvider>
    </ThemeProvider>
  );
};
