import { GENERAL_REDUCER_ACTIONS, GeneralActionCreator, GeneralReducerFunction } from '../types';

export const setDownloadModelData: GeneralActionCreator<GENERAL_REDUCER_ACTIONS.SET_DOWNLOAD_MODEL_DATA> = (
  value
) => ({
  type: GENERAL_REDUCER_ACTIONS.SET_DOWNLOAD_MODEL_DATA,
  value
});

export const setDownloadModelDataReducer: GeneralReducerFunction<GENERAL_REDUCER_ACTIONS.SET_DOWNLOAD_MODEL_DATA> = (
  state,
  payload
) => ({
  ...state,
  downloadModelData: payload
});
