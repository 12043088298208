import React, { useEffect, useLayoutEffect } from 'react';
import { Mixpanel } from 'infra/mixpanel';
import { useSelectors } from 'presentation/hooks';

Mixpanel.init();

type Props = {
  children: React.ReactNode;
};

export const MixpanelProvider = ({ children }: Props) => {
  const { isAuthenticated } = useSelectors.Auth();
  const { email = '' } = useSelectors.User();

  const distinctId = Mixpanel.getDistinctId();

  useLayoutEffect(() => {
    if (isAuthenticated) {
      if (distinctId !== email) {
        Mixpanel.identify(email);
      }
    } else {
      Mixpanel.reset();
    }
  }, [distinctId, email, isAuthenticated]);

  useEffect(() => {
    Mixpanel.track('marketplace:open');
  }, []);
  return <>{children}</>;
};
