import { styled, TextareaAutosize, TextField } from '@mui/material';

export const Container = styled('div')<{ error?: boolean }>(({ error }) => ({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  background: 'inherit',
  border: '1px solid #0000003B',
  borderRadius: '0.25rem',

  ...(error && {
    '& label': {
      color: '#D32F2F'
    }
  })
}));

export const Label = styled('label')(() => ({
  position: 'absolute',
  top: '-0.5rem',
  left: '0.75rem',
  padding: '0 0.25rem',
  fontSize: '0.75rem',
  color: '#00000099',
  zIndex: 1,
  background: '#FFFFFF'
}));

export const StyledInput = styled(TextField)(() => ({
  width: '100%',
  borderRadius: '0.25rem',
  '& fieldset': {
    border: 'none'
  }
}));

export const StyledTextarea = styled(TextareaAutosize)(() => ({
  width: '100%',
  height: '100% !important',
  padding: '1rem 0.75rem',
  resize: 'none',
  overflow: 'auto',
  border: 'none',
  outline: 'none',
  borderRadius: '0.25rem',
  color: '#000000DE',
  fontSize: '1rem',
  fontFamily: 'Roboto, sans-serif'
}));
