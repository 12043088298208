import React, { useMemo, useState } from 'react';
import { TableSection } from '../TableSection/TableSection';
import { Container } from './UpdatesTable.styles';
import { useCompanyServices, useDebounce, useParams } from 'presentation/hooks';
import { useSearchParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { UpdatesPageError } from '../UpdatesPageError/UpdatesPageError';

export const UpdatesTable = () => {
  const { companyId } = useParams<'LIVE_UPDATES'>();
  const [params] = useSearchParams();

  const activeFilters = useMemo(
    () =>
      decodeURIComponent(params.get('f') ?? '')
        .split('|')
        .filter(Boolean)
        .flatMap((f) => f.split(',')),
    [params]
  );
  const filingTypeVisible = useMemo(() => params.get('fth') !== 'true', [params]);
  const visiblePeriods = useMemo(() => {
    const periods = Number(params.get('vp') ?? 4);
    if (isNaN(periods)) return 4;
    return Math.min(Math.abs(periods), 4);
  }, [params]);

  const debouncedFilters = useDebounce(activeFilters, 1000);

  const { data, loading, error } = useCompanyServices().companyModelPreview({
    variables: { companyId, query: params.get('q') ?? undefined, filingTypes: debouncedFilters }
  });

  const [openSection, setOpenSection] = useState<number | undefined>(0);

  const periods = useMemo(() => data?.modelLivePreviewSections.periods ?? [], [
    data?.modelLivePreviewSections.periods
  ]);

  const periodsList = useMemo(() => {
    const start = 4 - visiblePeriods;

    return periods.slice(start);
  }, [periods, visiblePeriods]);
  const sections = useMemo(() => data?.modelLivePreviewSections.sections ?? [], [data]);

  const handleOnpenSection = (index: number) => () => {
    setOpenSection((prev) => (prev === index ? undefined : index));
  };

  const getIsSectionOpen = (index: number) => index === openSection;

  if (error) return <UpdatesPageError />;

  if (loading || !data) return <CircularProgress size={16} style={{ margin: '0 auto' }} />;

  return (
    <Container data-periods={periodsList.length} data-filing={filingTypeVisible}>
      <thead>
        <tr>
          <th></th>
          {filingTypeVisible && <th>Filing Type</th>}
          {periodsList.map((period) => (
            <th key={period}>{period}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {sections.map((section, index) => (
          <TableSection
            key={section.name}
            {...section}
            open={getIsSectionOpen(index)}
            onSectionClick={handleOnpenSection(index)}
            periods={periods}
            periodsList={periodsList}
            filingTypeVisible={filingTypeVisible}
          />
        ))}
      </tbody>
    </Container>
  );
};
