import { styled } from '@mui/material';

export const Iframe = styled('iframe')({
  height: '100%',
  border: 'none',

  '&.loading': {
    opacity: 0
  }
});
