import { styled } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  width: '100%',
  height: '100%'
});

export const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  width: '100%'
});

export const Header = styled('div')({
  position: 'relative',
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '50px',
  borderBottom: '1px solid #EEEEEE',
  padding: '10px 12px 10px 42px',
  fontSize: '0.75rem',
  color: '#000000DE'
});
