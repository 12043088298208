import { useMutation } from '@apollo/client';
import {
  EMAIL_VERIFICATION_SERVICE,
  EmailVerificationService,
  LOGIN_SERVICE,
  LoginService,
  LOGOUT_SERVICE,
  LogoutService,
  RESET_PASSWORD_SERVICE,
  ResetPasswordService,
  SIGN_UP_SERVICE,
  SignUpService,
  UPDATE_PASSWORD_SERVICE,
  UpdatePasswordService,
  USER_DETAILS_SERVICE
} from 'application/services';
import { DIALOG_IDS } from 'domain/models';
import { Cookies } from 'infra/storage';
import { routes } from 'main/router';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { loggedOut } from 'store';
import { authenticated, setUserData, toggleDialog, userStateCleared } from 'store/slices';

export const useAuthServices = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const useLogin = () =>
    useMutation<LoginService.Response, LoginService.Params>(LOGIN_SERVICE, {
      refetchQueries: ({ data }) => (data?.tokenAuth?.success ? [USER_DETAILS_SERVICE] : []),
      onCompleted: ({ tokenAuth }) => {
        if (tokenAuth.errors.length > 0) {
          const error = new Error(tokenAuth.errors[0]);
          error.name = 'ApplicationError';
          throw error;
        }
        if (tokenAuth.success) {
          dispatch(setUserData({ email: Cookies.getSessionCookie() }));
          dispatch(authenticated());
          dispatch(toggleDialog({ action: 'close', id: DIALOG_IDS.LOGIN }));
        }
        if (tokenAuth.token) {
          Cookies.set('accessToken', tokenAuth.token, {
            expires: 3650
          });
        }
      },
      onError: (error) => {
        if (error.name !== 'ApplicationError') {
          console.error(`[Marketplace] Authentication Errror: ${error.message}`);
        }
      }
    });

  const useEmailVerification = () =>
    useMutation<EmailVerificationService.Response, EmailVerificationService.Params>(
      EMAIL_VERIFICATION_SERVICE
    );

  const useLogout = () =>
    useMutation<LogoutService.Response, LogoutService.Params>(LOGOUT_SERVICE, {
      onCompleted: () => {
        navigate(routes.HOME);
        Cookies.removeSessionCookie();
        dispatch(userStateCleared());
        dispatch(loggedOut());
      }
    });

  const useResetPassword = () =>
    useMutation<ResetPasswordService.Response, ResetPasswordService.Params>(RESET_PASSWORD_SERVICE);

  const useSignUp = () =>
    useMutation<SignUpService.Response, SignUpService.Params>(SIGN_UP_SERVICE);

  const useUpdatePassword = () =>
    useMutation<UpdatePasswordService.Response, UpdatePasswordService.Params>(
      UPDATE_PASSWORD_SERVICE
    );

  return {
    emailVerification: useEmailVerification,
    login: useLogin,
    logout: useLogout,
    resetPassword: useResetPassword,
    signup: useSignUp,
    updatePassword: useUpdatePassword
  };
};
