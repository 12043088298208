import { styled } from '@mui/material';

export const Container = styled('table')<{ 'data-periods': number; 'data-filing': boolean }>(
  ({ 'data-periods': periods, 'data-filing': filing }) => ({
    position: 'relative',
    display: 'grid',
    borderCollapse: 'collapse',
    gridTemplateColumns: `4fr ${filing ? '1fr' : ''} repeat(${periods}, 1.5fr)`,

    '& > thead': {
      display: 'contents',

      '&, & th': {
        position: 'sticky',
        top: 60,
        zIndex: 1
      },

      '& > tr': {
        display: 'contents',

        '& > th': {
          padding: '0.53rem 1rem 0.525rem',
          background: '#FAFAFA',
          borderTop: '1px',
          borderBottom: '1px',
          borderLeft: 0,
          borderRight: 0,
          borderStyle: 'solid',
          borderColor: '#E8E8E8',
          color: '#00000099',
          fontSize: '0.75rem',
          fontWeight: 500,
          textAlign: 'left'
        }
      }
    },

    '& > tbody': {
      display: 'contents',

      '& > tr': {
        display: 'contents'
      }
    },

    '& td.full-width': {
      gridColumn: '1 / -1',
      display: 'flex',
      justifyContent: 'center',
      paddingBottom: '1rem'
    }
  })
);
