import { styled } from '@mui/material';

export const Container = styled('div', {
  shouldForwardProp: (props) => props !== 'menuExpanded' && props !== 'noPadding'
})<{ menuExpanded: boolean; noPadding: boolean }>(({ theme, menuExpanded, noPadding }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  maxWidth: 'calc(100% - 80px)',

  [theme.breakpoints.up('sm')]: {
    maxWidth: `${menuExpanded ? 'calc(100% - 290px)' : 'calc(100% - 80px)'}`
  },
  [theme.breakpoints.up('md')]: {
    padding: noPadding ? 0 : '0 38px'
  }
}));

export const Wrapper = styled('div', {
  shouldForwardProp: (props) => props !== 'noPadding'
})<{ noPadding: boolean }>(({ theme, noPadding }) => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  minHeight: 'fit-content',
  height: '100%',
  width: '100%',
  maxWidth: noPadding ? 'unset' : '1421px',
  margin: noPadding ? 0 : '0 auto',
  padding: noPadding ? 0 : '0 16px 16px',

  [theme.breakpoints.up('md')]: {
    padding: noPadding ? 0 : '0 24px'
  }
}));
