import { Button, ButtonGroup, Menu, styled } from '@mui/material';
import { SearchInput } from 'presentation/components';

export const Container = styled('div')({
  position: 'sticky',
  top: '0',
  zIndex: 1,
  background: '#FFFFFF',
  display: 'flex',
  flexWrap: 'wrap',
  gap: '1rem',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  marginTop: '1rem',
  padding: '1rem 0 0.75rem'
});

export const Wrapper = styled('div')({
  display: 'flex',
  alignItems: 'center',
  gap: '0.5rem'
});

export const StyledSearch = styled(SearchInput)({
  maxWidth: '400px'
});

export const StyledMenu = styled(Menu)({
  '& .MuiPaper-root': {
    padding: '0.5rem',
    border: '1px solid #E8E8E8',
    boxShadow: 'none'
  },

  '& .MuiList-root': {
    padding: 0
  },

  '& .MuiMenuItem-root': {
    justifyContent: 'space-between',
    width: '178px',
    minWidth: 'fit-content',
    padding: '0.5rem',
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    fontWeight: 500,
    color: ' #000000DE',

    '&:first-of-type': {
      borderBottom: '1px solid #00002F26',

      '& > svg': {
        fontSize: '1.5rem'
      }
    },

    '&.filing-type': {
      width: '100%'
    }
  }
});

export const StyledButtonGroup = styled(ButtonGroup)({
  '& > .MuiButtonGroup-grouped': {
    '&[data-active=true]': {
      pointerEvents: 'none'
    },

    '&:not(:last-of-type)': {
      marginRight: '1px',

      '&:hover': {
        borderRightColor: '#1976d2'
      }
    }
  }
});

export const DotMenu = styled(Button)({
  height: '30px',
  width: 'fit-content',
  minWidth: 'unset',
  padding: '0.25rem',
  fontSize: '1rem',
  fontWeight: 500,
  color: '#757575'
});

export const PeriodCounterWrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  width: '100%',
  padding: '0.5rem',

  '& > div': {
    display: 'flex',
    gap: '0.75rem',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
    textTrasform: 'uppercase',

    '&:first-of-type > span': {
      fontSize: '0.625rem',
      color: '#60646C',
      textTransform: 'capitalize'
    },

    '& .MuiButtonGroup-root': {
      '& > div': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginLeft: '-1px',
        width: '77px',
        border: '1px solid #E8E8E8',
        color: '#000509E3'
      }
    },

    '& .MuiButtonBase-root': {
      width: '30px',
      padding: 0
    }
  }
});
