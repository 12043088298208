import React from 'react';

export const CrosshairIcon = (props: React.SVGProps<SVGSVGElement>) => (
  <svg
    {...props}
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="16" height="16" fill="white" fillOpacity="0.01" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 0C8.29455 0 8.53333 0.238782 8.53333 0.533333V1.92733C11.405 2.23307 13.6711 4.56546 13.8739 7.46667H15.4667C15.7612 7.46667 16 7.70545 16 8C16 8.29455 15.7612 8.53333 15.4667 8.53333H13.8547C13.5567 11.3327 11.3327 13.5567 8.53333 13.8547V15.4667C8.53333 15.7612 8.29455 16 8 16C7.70545 16 7.46667 15.7612 7.46667 15.4667V13.8739C4.56546 13.6711 2.23307 11.405 1.92733 8.53333H0.533333C0.238782 8.53333 0 8.29455 0 8C0 7.70545 0.238782 7.46667 0.533333 7.46667H1.90812C2.11601 4.49244 4.49244 2.11601 7.46667 1.90812V0.533333C7.46667 0.238782 7.70545 0 8 0ZM8.53333 12.8343V10.1333C8.53333 9.83878 8.29455 9.6 8 9.6C7.70545 9.6 7.46667 9.83878 7.46667 10.1333V12.8576C5.12551 12.6602 3.24503 10.8446 2.94767 8.53333H5.86667C6.16122 8.53333 6.4 8.29455 6.4 8C6.4 7.70545 6.16122 7.46667 5.86667 7.46667H2.92448C3.12802 5.05239 5.05239 3.12802 7.46667 2.92448V5.86667C7.46667 6.16122 7.70545 6.4 8 6.4C8.29455 6.4 8.53333 6.16122 8.53333 5.86667V2.94767C10.8446 3.24503 12.6602 5.12551 12.8576 7.46667H10.1333C9.83878 7.46667 9.6 7.70545 9.6 8C9.6 8.29455 9.83878 8.53333 10.1333 8.53333H12.8343C12.5462 10.7724 10.7724 12.5462 8.53333 12.8343Z"
      fill="#60646C"
    />
  </svg>
);
