import React, { forwardRef, useEffect, useImperativeHandle, useMemo, useState } from 'react';
import { InnerSideBarContainer, PageInfo, StyledButtonGroup } from './SourcePage.styles';
import { useSearchParams } from 'react-router-dom';
import { useCompanyServices } from 'presentation/hooks';
import { CircularProgress } from '@mui/material';
import { ActionButton, Tooltip } from 'presentation/components';
import {
  ALPHABETS,
  evaluate,
  formatFormula,
  formatNumber,
  getFormulaObject,
  getOperators,
  getSeriesIdsFromFormula
} from './utils';
import { Fundamental, Series } from 'domain/models';
import { Mixpanel } from 'infra/mixpanel';
import { FundamentalsTable } from './FundamentalsTable';

export type SideBarRef = {
  collpased: boolean;
  loading: boolean;
  collapse: () => void;
  expand: () => void;
  toggle: () => void;
};

type Props = {
  selectedFundamental: string;
};

export const RolledUpSideBar = forwardRef<SideBarRef, Props>(({ selectedFundamental }, ref) => {
  const [search, setSearch] = useSearchParams();

  const companyId = search.get('company_id') ?? '';
  const period = search.get('period') ?? '';
  const formulaString = search.get('formula');
  const isYTD = search.get('isYTD') === 'true';

  const seriesIds = useMemo(() => (formulaString ? getSeriesIdsFromFormula(formulaString) : []), [
    formulaString
  ]);

  const formulaWithVariables = useMemo(() => {
    if (!formulaString) return '';
    return formatFormula(formulaString);
  }, [formulaString]);

  const formula = useMemo(() => {
    if (!formulaWithVariables) return;
    return getFormulaObject(formulaWithVariables);
  }, [formulaWithVariables]);

  const operators = useMemo(() => getOperators(formula), [formula]);

  const [collpased, setCollapsed] = useState(false);

  const { data: companyData, loading: isLoading } = useCompanyServices().companyDetails({
    variables: { companyId },
    skip: !companyId
  });

  const { data, loading } = useCompanyServices().seriesFundamental({
    variables: { companyId, periods: [period], seriesIds },
    skip: !companyId || !seriesIds.length || !period
  });

  const pageLoading = loading || isLoading || !companyData;

  useImperativeHandle(ref, () => ({
    collpased,
    loading: pageLoading,
    collapse: () => {
      setCollapsed(true);
    },
    expand: () => {
      setCollapsed(false);
    },
    toggle: () => {
      setCollapsed((prev) => !prev);
    }
  }));

  const seriesById = useMemo(() => {
    const seriesById: { [id: string]: Series } = {};
    data?.modelLivePreviewSeries.series.forEach((series) => {
      seriesById[series.id] = series;
    });
    return seriesById;
  }, [data?.modelLivePreviewSeries.series]);

  const fundamentals = useMemo(
    () =>
      data?.modelLivePreviewSeries.series.map(({ name, fundamentals }, index) => {
        const fundamental = fundamentals.find(
          (fundamental) => fundamental.period === period && fundamental.id !== '-1'
        );
        return {
          label: name,
          id: fundamental?.id ?? '-1',
          value: fundamental?.value,
          operator: fundamental ? operators[index - 1] : undefined
        };
      }) ?? [],
    [data?.modelLivePreviewSeries.series, operators, period]
  );

  const resultRow = useMemo(() => {
    if (!data?.modelLivePreviewSeries.series || !formula) {
      return {};
    }

    let label = String(formulaWithVariables);

    seriesIds.forEach((seriesId, index) => {
      label = label.replace(`[${ALPHABETS[index]}${seriesId}]`, seriesById[seriesId]?.name ?? '');
    });
    const fundamentalsBySeriesId: { [id: string]: Fundamental[] } = {};
    data.modelLivePreviewSeries.series.forEach((series) => {
      fundamentalsBySeriesId[series.id] = series.fundamentals;
    });

    const value = (() => {
      if (!formula) return;

      const number = formatNumber(evaluate(formula, fundamentalsBySeriesId, seriesIds, isYTD));
      return isFinite(parseFloat(number)) ? number : 'No Result';
    })();

    return {
      label,
      value
    };
  }, [
    data?.modelLivePreviewSeries.series,
    formula,
    formulaWithVariables,
    seriesIds,
    isYTD,
    seriesById
  ]);

  const handleToggleYTD = () => {
    setSearch((prev) => {
      const isYTD = prev.get('isYTD') === 'true';
      Mixpanel.track('marketplace:src:rollup:value-toggle', { isYTD: !isYTD });
      prev.set('isYTD', String(!isYTD));
      return prev;
    });
  };

  useEffect(() => {
    Mixpanel.track('marketplace:src:rollup:open', { companyId, period, formulaString });
  }, [companyId, formulaString, period]);

  if (pageLoading)
    return (
      <InnerSideBarContainer>
        <CircularProgress size={16} style={{ margin: ' 32px auto' }} />
      </InnerSideBarContainer>
    );

  const { name, ticker } = companyData?.company;

  return (
    <InnerSideBarContainer collapsed={collpased} fundamentalSelected={!!selectedFundamental}>
      <div>
        <PageInfo>
          Rolled-up Number
          <Tooltip title={`${ticker} - ${name}`}>
            <span>
              {ticker} - {name}
            </span>
          </Tooltip>
          <div>
            <StyledButtonGroup>
              <ActionButton bgLight={isYTD} onClick={handleToggleYTD} data-active={!isYTD}>
                Quarterized
              </ActionButton>
              <ActionButton bgLight={!isYTD} onClick={handleToggleYTD} data-active={isYTD}>
                YTD
              </ActionButton>
            </StyledButtonGroup>
          </div>
        </PageInfo>
        <FundamentalsTable
          columnLabel="Row Label"
          columnValue={period}
          fundamentals={fundamentals}
          result={resultRow}
        />
      </div>
    </InnerSideBarContainer>
  );
});
