import React, { Suspense } from 'react';
import { ApolloProvider } from '@apollo/client';
import { CircularProgress } from '@mui/material';
import { LicenseInfo } from '@mui/x-license-pro';
import { init } from 'commandbar';
import { gqlClient } from 'infra/http';
import { router, routerV1 } from 'main/router';
import { Provider as StoreProvider } from 'react-redux';
import { RouterProvider } from 'react-router';
import { useAppSelector } from 'store/hooks';
import { generalState } from 'store/slices';
import { store } from 'store';
import { StatsigProvider } from 'infra/statsig';
import { DatadogProvider } from 'infra/datadog';
import { FeatureFlagValidationProvider } from 'main/providers/FeatureFlagValidationProvider';
import { AuthProvider, MixpanelProvider } from 'main/providers';

init('3b514a4d');

const DATA_GRID_LICENSE = process.env.REACT_APP_DATA_GRID_LICENSE;
if (DATA_GRID_LICENSE) {
  LicenseInfo.setLicenseKey(DATA_GRID_LICENSE);
}

// TODO
// Needed until we remove the fallback to V1
// After removing the fallback, we can remove the routerV1 and this logic
const AppInner = () => {
  const {
    featureFlags: { isMarketplaceV2Enabled }
  } = useAppSelector(generalState);

  if (!isMarketplaceV2Enabled) return <RouterProvider router={routerV1} />;

  return <RouterProvider router={router} />;
};

export const App = () => {
  return (
    <React.StrictMode>
      <Suspense fallback={<CircularProgress size={16} style={{ margin: '48px auto' }} />}>
        <ApolloProvider client={gqlClient}>
          <StoreProvider store={store}>
            <MixpanelProvider>
              <AuthProvider>
                <StatsigProvider>
                  <DatadogProvider>
                    <FeatureFlagValidationProvider>
                      <AppInner />
                    </FeatureFlagValidationProvider>
                  </DatadogProvider>
                </StatsigProvider>
              </AuthProvider>
            </MixpanelProvider>
          </StoreProvider>
        </ApolloProvider>
      </Suspense>
    </React.StrictMode>
  );
};
