import { gql } from '@apollo/client';

export const COMPOSITE_FUNDAMENTAL_INFO_SERVICE = gql`
  query getCompositeFundamentals($fundamentalId: Int!) {
    compositeFundamentals(fundamentalId: $fundamentalId) {
      attributeName
      operations {
        value
        period
        operator
        id
      }
      result {
        value
        period
      }
    }
  }
`;

export namespace CompositeFundamentalInfoService {
  export type Params = {
    fundamentalId: string;
  };

  export type Response = {
    compositeFundamentals: FundamentalInfo;
  };

  type Operation = {
    value: string;
    period: string;
    operator: string;
    id: string;
  };

  type Result = {
    value: string;
    period: string;
  };

  type FundamentalInfo = {
    attributeName: string;
    operations: Operation[];
    result: Result;
  };
}
