import { styled } from '@mui/material';

export const Container = styled('div')({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  padding: '0 16px 0 24px',
  border: '1px solid #CFD1D1',
  borderRadius: '28px',

  '& svg': {
    minWidth: '24px',
    cursor: 'pointer'
  }
});

export const Input = styled('input')({
  width: '100%',
  padding: '6px 4px',
  fontSize: '16px',
  color: '#000000DE',
  border: 'none',
  outline: 'none',
  background: 'transparent',

  '&::placeholder': {
    fontSize: '14px',
    color: '#49454Fh'
  },

  '&:placeholder-shown': {
    textOverflow: 'ellipsis'
  }
});
