import { gql } from '@apollo/client';
import { MODEL_SUBSCRIPTION_ERRORS } from 'domain/models';

export const MODEL_SUBSCRIBE_SERVICE = gql`
  mutation activateModelMutation($companyId: String!) {
    activateModel(companyId: $companyId) {
      errors
      success
      userAccessesCompany {
        id
        companyId
        accessMode
      }
    }
  }
`;

export namespace ModelSubscribeService {
  export type Params = {
    companyId: number | string;
  };

  export type Response = {
    activateModel: {
      errors: Array<keyof typeof MODEL_SUBSCRIPTION_ERRORS>;
      success: boolean;
      userAccessesCompany: {
        id: string;
        companyId: number;
        accessMode: string;
      };
    };
  };
}
