import React, { useMemo, useRef, useState } from 'react';
import {
  Container,
  DotMenu,
  PeriodCounterWrapper,
  StyledButtonGroup,
  StyledMenu,
  StyledSearch,
  Wrapper
} from './SubMenu.styles';
import {
  ActionButton,
  Checkbox,
  EyeClosedIcon,
  EyeOpenIcon,
  FilterIcon
} from 'presentation/components';
import { MenuItem } from '@mui/material';
import { FILING_TYPES_OPTIONS } from 'domain/models';
import { useSearchParams } from 'react-router-dom';
import { Mixpanel } from 'infra/mixpanel';

export const SubMenu = () => {
  const [params, setParams] = useSearchParams();

  const columnFilterAnchor = useRef<HTMLButtonElement>(null);
  const filterAnchor = useRef<HTMLButtonElement>(null);
  const filterValues = Object.entries(FILING_TYPES_OPTIONS);

  const [filterMenuOpen, setFilterMenuOpen] = useState(false);
  const [columFilterOpen, setColumFilterOpen] = useState(false);

  const activeFilters = useMemo(
    () =>
      decodeURIComponent(params.get('f') ?? '')
        .split('|')
        .filter(Boolean)
        .flatMap((f) => f.split(',')),
    [params]
  );
  const filingTypeVisible = useMemo(() => params.get('fth') === 'true', [params]);
  const visiblePeriods = useMemo(() => {
    const periods = Number(params.get('vp') ?? 4);
    if (isNaN(periods)) return 4;
    return Math.min(Math.abs(periods), 4);
  }, [params]);

  const isTimeView = params.get('d') === 'time';

  const eyeIcon = filingTypeVisible ? <EyeClosedIcon /> : <EyeOpenIcon />;

  const isChecked = (value: string[]) => value.some((v) => activeFilters.includes(v));

  const handleToggleFilterMenu = () => {
    setFilterMenuOpen((prev) => !prev);
  };

  const handleToggleColumnFilter = () => {
    setColumFilterOpen((prev) => !prev);
  };

  const handleSetFilter = (value: string[]) => () => {
    setParams((p) => {
      const activeFilters: string[] = decodeURIComponent(params.get('f') ?? '')
        .split('|')
        .filter(Boolean);
      const joinedValue = value.join();

      if (activeFilters.includes(joinedValue)) {
        // Remove the filter if it already exists
        const newFilters = activeFilters.filter((filter) => filter !== joinedValue);

        if (!newFilters.length) {
          p.delete('f');
        } else {
          p.set('f', encodeURIComponent(newFilters.join('|')));
        }
      } else {
        // Add the new filter
        activeFilters.push(joinedValue);
        p.set('f', encodeURIComponent(activeFilters.join('|')));
      }

      return p;
    });
  };

  const handleClearFilters = () => {
    setParams((p) => {
      p.delete('f');
      return p;
    });
  };

  const handleDisplayToggle = () => {
    setParams((p) => {
      const isTimeView = p.get('d') === 'time';
      if (isTimeView) {
        p.delete('d');
      } else {
        p.set('d', 'time');
      }

      Mixpanel.track('marketplace:updates_page:view_change', {
        isTimeView: !isTimeView
      });

      return p;
    });
  };

  const handleToggleFilingType = () => {
    setParams((p) => {
      const hidden = p.get('fth') === 'true';
      if (hidden) {
        p.delete('fth');
      } else {
        p.set('fth', 'true');
      }
      return p;
    });
  };

  const handleVisiblePeriods = (action: '+' | '-') => () => {
    setParams((p) => {
      const value = Number(p.get('vp') ?? 4);
      if (action === '+') {
        if (value < 3) {
          p.set('vp', (value + 1).toString());
        } else {
          p.delete('vp');
        }
      }

      if (action === '-' && value > 1) {
        p.set('vp', (value - 1).toString());
      }

      return p;
    });
  };

  return (
    <Container>
      <Wrapper>
        <StyledSearch searchEventName="marketplace:updates_page:search" />
        <ActionButton ref={filterAnchor} bgLight onClick={handleToggleFilterMenu}>
          <FilterIcon /> Filing type
        </ActionButton>
        <StyledMenu
          anchorEl={filterAnchor.current}
          open={filterMenuOpen}
          onClose={handleToggleFilterMenu}
          sx={{ mt: '4px' }}
        >
          <MenuItem sx={{ justifyContent: 'end' }} onClick={handleClearFilters}>
            Clear Filters
          </MenuItem>
          {filterValues.map(([key, value]) => (
            <MenuItem key={key} onClick={handleSetFilter(value)}>
              {key} <Checkbox checked={isChecked(value)} />
            </MenuItem>
          ))}
        </StyledMenu>
      </Wrapper>
      <Wrapper>
        SHOW
        <StyledButtonGroup>
          <ActionButton
            bgLight={isTimeView}
            onClick={handleDisplayToggle}
            data-active={!isTimeView}
          >
            Value
          </ActionButton>
          <ActionButton
            bgLight={!isTimeView}
            onClick={handleDisplayToggle}
            data-active={isTimeView}
          >
            Update time
          </ActionButton>
        </StyledButtonGroup>
        <DotMenu ref={columnFilterAnchor} variant="text" onClick={handleToggleColumnFilter}>
          ...
        </DotMenu>
        <StyledMenu
          anchorEl={columnFilterAnchor.current}
          open={columFilterOpen}
          onClose={handleToggleColumnFilter}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right'
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
          sx={{ mt: '13px' }}
        >
          <MenuItem className="filing-type" onClick={handleToggleFilingType}>
            Filing type {eyeIcon}
          </MenuItem>
          <PeriodCounterWrapper>
            <div>
              SHOW LAST <span>Min: 1 / Max: 4</span>
            </div>
            <div>
              <StyledButtonGroup>
                <ActionButton
                  data-active={visiblePeriods === 1}
                  bgLight
                  onClick={handleVisiblePeriods('-')}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M6 13V11H18V13H6Z" fill="#49454F" />
                  </svg>
                </ActionButton>
                <div>{visiblePeriods}</div>
                <ActionButton
                  data-active={visiblePeriods === 4}
                  bgLight
                  onClick={handleVisiblePeriods('+')}
                >
                  <svg
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M11 13H5V11H11V5H13V11H19V13H13V19H11V13Z" fill="#49454F" />
                  </svg>
                </ActionButton>
              </StyledButtonGroup>
              PERIODS
            </div>
          </PeriodCounterWrapper>
        </StyledMenu>
      </Wrapper>
    </Container>
  );
};
