import React from 'react';
import { Container, Title, Wrapper } from './FreeTierBanner.styles';
import { Button } from '@mui/material';
import { useSelectors } from 'presentation/hooks';

export const FreeTierBanner = () => {
  const { maxSubscriptions = 0, subscribedCount = 0 } = useSelectors.User();

  const limitReached = subscribedCount >= maxSubscriptions;

  return (
    <Container>
      <Wrapper>
        <Title>Schedule Your Personalized Demo</Title>
        {limitReached &&
          'Follow limit reached. See Daloopa in action—book a live walkthrough and get the full experience today!'}
        {!limitReached &&
          'Free users can follow 3 companies and get a glimpse of Daloopa’s potential. Ready to see it in action? Book a live walkthrough to discover the full experience!'}
      </Wrapper>
      <a href={process.env.REACT_APP_DEMO_URL} target="_blank" rel="noreferrer">
        <Button size="small" variant="outlined">
          Book your demo now
        </Button>
      </a>
    </Container>
  );
};
