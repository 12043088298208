import React, { useMemo, useState } from 'react';

import { ChevronLeft } from '@material-ui/icons';
import { Container, Content, Details, PageInfo, ToggleButton } from './SideBar.styles';
import { Tooltip } from '../Tooltip/Tooltip';
import { FundamentalType } from 'domain/models';
import { ActionButton } from '../ActionButton/ActionButton';
import { DocumentReportAnIssue } from '../DocumentReportAnIssue/DocumentReportAnIssue';
import { SideBarRef } from 'presentation/pages/source/RolledUpSideBar';
import { ChevronLeftDouble } from '../Icons';
import { Mixpanel } from 'infra/mixpanel';
import { CircularProgress } from '@mui/material';

type Props = {
  id: string;
  children?: React.ReactNode;
  company?: {
    ticker: string;
    name: string;
  };
  document?: {
    reportedDate: string;
    filingType: string;
    documentType: string;
    url: string;
  };
  iframeRef: React.RefObject<HTMLIFrameElement>;
  innerSideBarRef?: React.RefObject<SideBarRef>;
  type?: FundamentalType | 'document' | 'rollup';
  loading?: boolean;
};

export const SideBar = ({
  id,
  children,
  company,
  document,
  iframeRef,
  innerSideBarRef,
  loading,
  type
}: Props) => {
  const [panelCollapsed, setPanelCollapsed] = useState(false);

  const title = useMemo(() => {
    if (type === FundamentalType.COMPOSITE) return 'Cumulative number';
    if (type === 'document') return 'Document Viewer';
    if (type === 'rollup') return 'Rolled-up number';

    return 'Datapoint Detail';
  }, [type]);

  const isRolledUp = type === 'rollup';

  const documentUrl = document?.url?.startsWith('https://www.sec.gov/Archives/edgar/data/')
    ? document?.url
    : undefined;

  const fundamentalAvailable =
    type !== FundamentalType.COMPOSITE && type !== FundamentalType.FILLER_VALUE && !isRolledUp;

  const hasInnerPanel = !!innerSideBarRef?.current;

  const isLoading = !company || loading;

  const handleToggleAll = () => {
    if (panelCollapsed && innerSideBarRef?.current?.collpased) {
      innerSideBarRef?.current?.expand();
      setPanelCollapsed(false);
    }

    if (!panelCollapsed && innerSideBarRef?.current?.collpased) {
      setPanelCollapsed(true);
    }

    if (panelCollapsed && !innerSideBarRef?.current?.collpased) {
      innerSideBarRef?.current?.collapse();
    }

    if (!panelCollapsed && !innerSideBarRef?.current?.collpased) {
      setPanelCollapsed(true);
      innerSideBarRef?.current?.collapse();
    }
    Mixpanel.track('marketplace:src:sidebar:toggle-all', { collapsed: !panelCollapsed });
  };

  const handleTogglePanel = () => {
    if (innerSideBarRef?.current) {
      handleToggleAll();
    } else {
      setPanelCollapsed((prev) => {
        Mixpanel.track('marketplace:src:sidebar:toggle', { collapsed: !prev });

        return !prev;
      });
    }
  };

  const handleSaveAsPdf = () => {
    Mixpanel.track('marketplace:src:sidebar:save-as-pdf');
    if (iframeRef?.current?.contentWindow) {
      iframeRef?.current?.contentWindow.print();
    } else {
      window.print();
    }
  };

  const handleSECClick = () => {
    Mixpanel.track('marketplace:src:sidebar:sec-click');
  };

  return (
    <Container>
      <ToggleButton collapsed={panelCollapsed} onClick={handleTogglePanel}>
        {!hasInnerPanel && <ChevronLeft />}
        {hasInnerPanel && <ChevronLeftDouble />}
      </ToggleButton>
      <Content collapsed={panelCollapsed}>
        {isLoading && <CircularProgress size={16} style={{ margin: ' 32px auto' }} />}
        {!isLoading && (
          <div>
            <PageInfo>
              {title}
              <Tooltip title={`${company.ticker} - ${company.name}`}>
                <span>
                  {company.ticker} - {company.name}
                </span>
              </Tooltip>
              {!!document && (
                <Details>
                  <div>
                    <span>Filing</span>
                    {document?.filingType}
                  </div>
                  <div>
                    <span>Exhibit</span>
                    {document?.documentType}
                  </div>
                  <div>
                    <span>Filed on</span>
                    {document?.reportedDate}
                  </div>
                </Details>
              )}
            </PageInfo>
            <div className="content-wrapper">{children}</div>
            {fundamentalAvailable && (
              <>
                <ActionButton bgLight onClick={handleSaveAsPdf}>
                  Save as pdf
                </ActionButton>
                <a href={documentUrl} target="_blank" rel="noreferrer" onClick={handleSECClick}>
                  <ActionButton bgLight disabled={!documentUrl}>
                    View on SEC EDGAR
                  </ActionButton>
                </a>
              </>
            )}
            {!isRolledUp && <DocumentReportAnIssue id={id} />}
          </div>
        )}
      </Content>
    </Container>
  );
};
