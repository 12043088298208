import React, { useCallback, useEffect } from 'react';

import { DIALOG_IDS } from 'domain/models';
import { useFormik } from 'formik';
import { Mixpanel } from 'infra/mixpanel';
import { Button, Dialog, ErrorOutlinedIcon, TextInput } from 'presentation/components';
import { useAuthServices, useSelectors } from 'presentation/hooks';
import { useDispatch } from 'react-redux';
import { toggleDialog } from 'store';
import { object, string } from 'yup';
import { Alert, Container, Header, Separator } from './LoginDialog.styles';
import { openRoutesList, routes } from 'main/router';
import { useLocation, useNavigate } from 'react-router';

const loginObjectShape = object({
  email: string().email().required(),
  password: string().required().min(8, 'Password needs to have at least 8 characters.')
});

type FormParams = {
  email: string;
  password: string;
};

export const LoginDialog = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [login, { error, loading, reset }] = useAuthServices().login();

  const { isAuthenticated } = useSelectors.Auth();
  const { activeDialogIDs } = useSelectors.General();

  const onSubmit = useCallback(
    ({ email, password }: FormParams) => {
      Mixpanel.track('marketplace:login:form:submit', { email });
      void login({ variables: { email, password } });
    },
    [login]
  );

  const formikObj = useFormik<FormParams>({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: loginObjectShape,
    onSubmit
  });

  const handleSignup = () => {
    Mixpanel.track('marketplace:login:signup:click');
    dispatch(toggleDialog({ action: 'close', id: DIALOG_IDS.LOGIN }));
  };
  const handlePasswordReset = () => {
    Mixpanel.track('marketplace:login:password-reset:click');
    dispatch(toggleDialog({ action: 'close', id: DIALOG_IDS.LOGIN }));
    dispatch(toggleDialog({ action: 'open', id: DIALOG_IDS.RESET_PASSWORD }));
  };

  const handleClose = useCallback(() => {
    if (!isAuthenticated && !openRoutesList.includes(location.pathname)) {
      navigate(routes.HOME);
    }
    formikObj.resetForm();
    reset();
  }, [formikObj, isAuthenticated, location.pathname, navigate, reset]);

  const hasErrors = (!formikObj.isValid && formikObj.submitCount > 0) || !!error;

  useEffect(() => {
    if (isAuthenticated && activeDialogIDs.includes(DIALOG_IDS.LOGIN)) {
      dispatch(toggleDialog({ action: 'close', id: DIALOG_IDS.LOGIN }));
    }
  }, [activeDialogIDs, dispatch, isAuthenticated, reset]);

  return (
    <Dialog id={DIALOG_IDS.LOGIN} onClose={handleClose}>
      <Container>
        <Header>Welcome to Daloopa!</Header>
        {hasErrors && (
          <Alert>
            <ErrorOutlinedIcon /> Incorrect email or password
          </Alert>
        )}
        <form onSubmit={formikObj.handleSubmit}>
          <TextInput
            name="email"
            placeholder="example@email.com"
            label="Email Address"
            autoComplete="email"
            onChange={formikObj.handleChange}
            value={formikObj.values.email}
            onBlur={formikObj.handleBlur}
          />
          <TextInput
            name="password"
            placeholder="Enter Password"
            label="Password"
            type="password"
            autoComplete="current-password"
            value={formikObj.values.password}
            onChange={formikObj.handleChange}
            onBlur={formikObj.handleBlur}
          />
          <Button
            type="submit"
            variant="contained"
            size="large"
            className="login-button"
            disabled={loading}
          >
            Log in
          </Button>
        </form>
        <Separator />
        <a
          href="https://daloopa.com/create-an-account"
          target="_blank"
          rel="noreferrer"
          onClick={handleSignup}
        >
          <Button variant="outlined" size="large" disabled={loading}>
            Sign up for free
          </Button>
        </a>
        <Button color="secondary" onClick={handlePasswordReset} disabled={loading}>
          Reset Password
        </Button>
      </Container>
    </Dialog>
  );
};
